import ServiceBase from '~/@core/serviceBase'
import sessionManager from '~/@managers/sessionManager';
import navigationManager from '~/@managers/navigationManager';
import cacheManager from '~/@managers/cacheManager';
import { AppError } from '~/@core/types/errorTypes';
import { RunLinkedApi } from '~/@api/runLinkedApi';
import QueryString from 'qs';
import { SignInDataApiModel } from '~/@api/runLinkedApi/models/authApiModels';
import runlinkedApiReference from '~/@api/runLinkedApi/runlinkedApiReference';
import logManager from '~/@managers/logManager';
import webPushManager from '~/@managers/webPushManager';


class AuthService extends ServiceBase {
    private _runlinkedApi = new RunLinkedApi();

    public async login(email: string, password: string): Promise<void> {
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();
        const data = QueryString.stringify({
            "grant_type": "password",
            "username": email,
            "password": password
        });

        const requestConfig = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        };

        await cacheManager.clearCachedPromise();
        return this.asServicePromise<any, any>(runlinkedApiClient.post<SignInDataApiModel>(runlinkedApiReference.Routes.identity.oauth.token.post, data, requestConfig)).then(
            async response => {
                await sessionManager.setSession(response.result.access_token);
                return response;
            },
            (error: AppError) => {

                if (error.code === 'EMAIL_CONFIRM_REQUIRED')
                    navigationManager.pushRoute('auth.emailConfirm');


                return Promise.reject(error);
            }
        );
    }

    public async externalLogin(provider: string): Promise<any> {
        const request = {
        };

        const externalLogins = await this.getExternalLogins(`${navigationManager.getFullUrl(navigationManager.resolveUrl('auth.oAuthComplete'))}`);

        const loginByProvider = externalLogins.toDictionary(m => m.name);
        const login = loginByProvider.item(provider);

        return login;
    }

    public async externalToken(token: string): Promise<any> {
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();
        const apiRequest = { token };
        return this.asServicePromise<any, any>(runlinkedApiClient.post(runlinkedApiReference.Routes.identity.oauth.externallogin.token.post, apiRequest)).then(
            async response => {
                await sessionManager.setSession(response.result.access_token);
                return response;
            }
        );
    }

    public async registerExternal(email: string, token: string): Promise<any> {
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();
        const apiRequest = { email, token };
        return this.asServicePromise<any, any>(runlinkedApiClient.post(runlinkedApiReference.Routes.identity.oauth.externallogin.register.post, apiRequest)).then(
            async response => {
                await sessionManager.setSession(response.result.access_token);
                return response;
            }
        );
    }

    public async logout(): Promise<void> {
        await sessionManager.clearActiveSession();
        await cacheManager.clearCachedPromise()
        navigationManager.redirectToLogin();
    }

    public async getExternalLogins(returnUrl: string): Promise<any> {
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();
        return this.asCachedServicePromise('externalLogins', undefined, () => runlinkedApiClient.get(runlinkedApiReference.Routes.identity.oauth.externallogin.get(returnUrl)));
    }

    public async isUserAuthenticated(): Promise<boolean> {
        const session =  await sessionManager.getActiveSession();
        return session !== null;
    }

    public async sendPasswordResetRequest(email : string) : Promise<boolean>{
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();
        return this.asServicePromise(runlinkedApiClient.post(runlinkedApiReference.Routes.account.password.forgotten.post, { email }), response => true)
    }


    public async resetPassword(token : string, userId : string, password : string, passwordConfirmation : string) : Promise<boolean>{
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();
        return this.asServicePromise(runlinkedApiClient.post(runlinkedApiReference.Routes.account.password.reset.post, { token, userId, password, passwordConfirmation }), response => {
            return true;
        })
    }


    //#region -> PRIVATES //////////////////////////////////////////////////////////////
    //#endregion
}

export default new AuthService();
