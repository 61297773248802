import themeReference, { ThemeColorVariant } from "~/@references/themeReference";

export type LogType = 'debug' | 'log' | 'info' | 'warning' | 'error';

export type LogData = {
    time : Date,
    data : any[],
    type : LogType,
    color? : ThemeColorVariant,
    context : string 
};

const _logKeeper : LogData[] = [];

class LogManager {

    //TODO : (JES) -> use configmanager once dependencyManager ready
    private consoleEnabled : boolean = true;// process.env.NODE_ENV === 'development';

    public log(context : string, data : () => any[]);
    public log(context : string, data : () => any[], color : ThemeColorVariant);
    public log(context : string, data : () => any[], keep : boolean);
    public log(context : string, data : () => any[], color : ThemeColorVariant, keep : boolean);
    public log(param1, param2, param3?, param4?) : void {

        const context : string = param1;
        const dataArray : any[] = param2();
        const color : ThemeColorVariant = typeof(param3) !== 'boolean' ? param3 : null;
        const keep : boolean = param4 !== undefined ? param4 : (typeof(param3)) === 'boolean' ? param3 : false;

        if(keep)
            _logKeeper.push({
                data : dataArray,
                color,
                context,
                type : 'log',
                time : new Date(),
            })

        this.internalLog(context, dataArray, color);
    }
    
    public debug(context : string, ...data : any[]) : void {
        
        const color : ThemeColorVariant = 'gray';

        _logKeeper.push({
            data,
            context,
            color,
            type : 'debug',
            time : new Date(),
        });
        this.internalLog(context, data, color, true);
    }
    
    public info(context : string, ...data : any[]) : void {

        const color : ThemeColorVariant = 'info';

        _logKeeper.push({
            data,
            context,
            color,
            type : 'info',
            time : new Date(),
        });
        
        this.internalLog(context, data, color);
    }
    
    public warning(context : string, ...data : any[]) : void {

        const color : ThemeColorVariant = 'warning';

        _logKeeper.push({
            data,
            context,
            color,
            type : 'warning',
            time : new Date(),
        });

        this.internalLog(context, data, color);
    }

    public error(context : string, ...data : any[]) : void {

        const color : ThemeColorVariant = 'danger';

        _logKeeper.push({
            data,
            context,
            color,
            type : 'error',
            time : new Date(),
        });
        
        this.internalLog(context, data, color, true);
    }

    public console(enabled : boolean = true) {
        this.consoleEnabled = enabled;
    }

    public list(clear : boolean) {

        if(clear)
            console.clear();
        
        _logKeeper.forEach(m => {
            this.internalLog(m.context, m.data, m.color);
        });
    }

    //#region PRIVATES ////////////////////////////////////////////////////////////////
    private internalLog(context : string, data : any[], color : ThemeColorVariant, printTrace : boolean = false) : void {

        if(!this.consoleEnabled)
            return;

        const message = typeof(data[0]) === 'string' 
            ? data[0] 
            : (data[0]?.stacktrace || data[0]?.type ? data[0]?.message : '');
        const firstLogIndex = typeof(data[0]) === 'string' ? 1 : 0;
        
        if(color) {
            if(context){
                console.groupCollapsed(`%c ${context} %c-> ${message}`, this.getLogContextStyles(color), this.getLogMessageStyles(color));
                for(let i = firstLogIndex ; i < data.length; i++){
                    console.log(data[i]);
                }
                if(printTrace)
                    console.trace();
                console.groupEnd();
            }
            else {
                if(data.length > 1 || typeof(data[0]) !== 'string'){
                    console.groupCollapsed(`%c ${message}`, this.getLogContextStyles(color));
                    for(let i = firstLogIndex; i < data.length; i++){
                        console.log(data[i]);
                    }
                    if(printTrace)
                        console.trace();
                    console.groupEnd();
                }
                else {
                    console.log(`%c ${message}`, this.getLogMessageStyles(color));
                }
            }
        }
        else {
            console.groupCollapsed(context ? `${context} -> ${message}` : `-> ${message}`);
            for(let i = firstLogIndex; i < data.length; i++){
                console.log(data[i]);
            }
            console.groupEnd();
        }
    }

    private getLogContextStyles(color : ThemeColorVariant) : string{
        const styles = [
            `border-radius : 4px`,
            `padding:2px 2px`,
            `margin: 5px 0`,
            `background-color: ${themeReference.colorHexByColorVariant.item(color)}`,
            `color : ${themeReference.colorHexByColorVariant.item('light')}`
        ];

        return styles.join(';');
    } 

    private getLogMessageStyles(color : ThemeColorVariant) : string{
        const styles = [
            `color : ${themeReference.colorHexByColorVariant.item(color)}`
        ];

        return styles.join(';');
    } 
    //#endregion
}

const logManager = new LogManager();
(window as any).logManager = logManager;

export default logManager;