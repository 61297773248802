
import { KeyOf } from '~/@core/types/commonTypes';
import router from '~/router';
import { Route } from 'vue-router';
import { RouteKeyReference } from '~/@references/appReference';
import configManager from './configManager';

export interface IRouteOptions {
    params? : object;
    query? : object;
}

export interface IResolveUrlOptions extends IRouteOptions {
    fullUri? : boolean
}

export type ResolvedRoute = {
    href : string;
    resolved : Route;
    // route : string;
}


class NavigationManager {
    
    public getFullUrl(route : string){
        return `${window.location.origin}${route}`;
    }

    public async redirectToMainPage() : Promise<Route> {   
        return this.pushRoute('activities');
    }
    
    public async redirectToLogin(returnUrl? : string) : Promise<Route> {    
        
        if(returnUrl)
            return this.pushRoute('auth.login', { query : { returnUrl : encodeURIComponent(returnUrl) } });
            
        return this.pushRoute('auth.login');
    }

    public async redirectToHome() : Promise<Route> {       
        return this.pushRoute('home');
    }
    
     
    //#region -> BASE METHODS ////////////////////////////////////////////////////////////////
    public resolveRoute(name: RouteKeyReference, options : IRouteOptions = {}) : ResolvedRoute{
        const resolveOptions = { name : name } as any;

        if(options.params) resolveOptions.params = options.params;
        if(options.query) resolveOptions.query = options.query;

        return router.resolve(resolveOptions) as any as ResolvedRoute;
    }

    public resolveUrl(name: RouteKeyReference, options : IResolveUrlOptions = {}): string {

        const fullUri = options.fullUri ?? false;
        const route = this.resolveRoute(name, options)
        const uri =  (fullUri) ? `${window.location.origin}${route.href}` : route.href;
        return uri;
    }

    public resolveRouterLink(name: RouteKeyReference, options : IRouteOptions = {}): string
    {
        return this.resolveRoute(name, options).resolved.fullPath;
    }

    public pushRoute(name: RouteKeyReference, options : IRouteOptions = {}): Promise<Route> {
        const routeOptions: any = { name : name };
        if(options.params) routeOptions.params = options.params;
        if(options.query) routeOptions.query = options.query;

        return router.push(routeOptions)
    }

    public pushPath(path : string, options : IRouteOptions = {}): Promise<Route> {
        const routeOptions: any = { name : name };
        if(options.params) routeOptions.params = options.params;
        if(options.query) routeOptions.query = options.query;
        
        routeOptions.path = path;

        return router.push(routeOptions);
    }    

    public replaceRoute(name : RouteKeyReference, options : IRouteOptions = {}) {
        this.replacePath(this.resolveRouterLink(name, options));
    }

    public replacePath(path : string) {
        if(!configManager.isAppNative)
            history.replaceState(null, null, path);        
    }
    //#endregion
}

export default new NavigationManager()