import { ConfigKeyReference, NativePlatformType } from "~/@references/appReference";
import logManager from "./logManager";

const isDev = process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'dev';
const isNative = process.env.VUE_APP_native === 'true'
const isIos = process.env.CORDOVA_PLATFORM === 'ios'
const isAndroid = process.env.CORDOVA_PLATFORM === 'android'

if(isDev) {
    logManager.info(`ConfigManager`, `/!\\ LOADED CONFIGURATION /!\\`, process.env);
}

class ConfigManager {

    protected prefix : string = 'VUE_APP_';
    private _initialized : boolean = false;

    constructor(){
    }
    public isDev() {
        return isDev;
    }    

    public get isAppNative() : boolean {
        return isNative;
    }

    public get isIos() : boolean {
        return isIos
    }

    public get isAndroid() : boolean {
        return isAndroid
    }

    public getNativePlatform() : NativePlatformType {

        if(this.isAndroid) return 'android';
        if(this.isIos) return 'ios';

        return undefined;
    }

    public getVersion(){      
        return this.getConfigInternal('VERSION', true);
    }
    
    public getConfig(key : ConfigKeyReference) : any {      
        return this.getConfigInternal(key, true);
    }
    
    public getConfigAsBoolean(key : ConfigKeyReference) : boolean{
        const conf = this.getConfig(key);

        if(conf === true || conf === '1' || conf.toLowerCase() === 'true')
            return true;
            
        if(conf === false || conf === '' || conf === '0' || conf.toLowerCase() === 'false')
            return false;

        return undefined;
    }

    public getConfigAsNumber(key : ConfigKeyReference) : number {
        return Number(this.getConfig(key));
    }

    public getApiOrigin() {    
        const origin = this.getConfigInternal('apiUri', true);
        return origin.startsWith('http') 
            ? origin
            : window.location.origin;
    }
    
    //#region -> PRIVATES ///////////////////////////////////////////////////////////////////////////
    protected getConfigInternal(key : string, vueAppKey : boolean = false) : any {
        
        key = vueAppKey ? `${this.prefix}${key}` : key;

        if(typeof process.env[key] === undefined)
            throw new Error(`Config key ${key} was not found`);

        const value = process.env[key];

        if(!value)
            logManager.warning('ConfigManager.getConfigInternal', `/!\\ key value is "${key}" seems not to be defined properly`, value);
        
        return value;
    }
    //#endregion
}

export default new ConfigManager();