import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, integer, length, confirmed } from 'vee-validate/dist/rules';
import { CollapseTransition, SlideYDownTransition, FadeTransition, ZoomCenterTransition } from "vue2-transitions";
import regionManager from "./@managers/regionManager";

extend('integer', integer);
extend('email', { ...email, message: regionManager.translate('common.error.validation.wrongEmailFormatMessage') });
extend('required', { ...required, message: regionManager.translate('common.error.validation.fieldRequiredMessage') });
extend('length', length);
extend('confirmed', confirmed);

export default {
  install(Vue) {
    // -> Vee-validate
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    // -> vue2-transitions
    Vue.component("CollapseTransition", CollapseTransition);
    Vue.component("SlideYDownTransition", SlideYDownTransition);
    Vue.component("FadeTransition", FadeTransition);
    Vue.component("ZoomCenterTransition", ZoomCenterTransition);
  }
};