
// -> core
import { Component, Vue, Prop } from "vue-property-decorator";
import { ComponentVueBase } from "~/@core/componentVueBase";

@Component({})
export default class Loader extends ComponentVueBase {

  //#region -> PROPS ////////////////////////////////////////////////
  @Prop({ default : false})
  loading : boolean;
  @Prop({ default : false, required: false})
  inline : boolean;
  //#endregion

  //#region -> PUBLICS ////////////////////////////////////////////////
  public show(){
    this.loading = true;
  }

  public hide() {
    this.loading = false;
  }
  //#endregion
}
