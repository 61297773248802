import Vue from 'vue';
import fr from './fr';
import VueI18n from 'vue-i18n';

export type ResourceReferences = {
    common : {
        actions : {
            cancel
        },

        alert : {
            title :{
                errorMessageTitle
            },
            message : {
                defaultUnAuthorizedErrorMessage,
                defaultNotFoundErrorMessage,
                defaultTechnicalErrorMessage,
                defautltNotImplementedErrorMessage
            }
        },

        error :  {
            validation : {
                fieldRequiredMessage,
                wrongEmailFormatMessage
            }
        }
    }
}

Vue.use(VueI18n)

const i18n = new VueI18n({ locale: 'fr',  fallbackLocale: 'fr', messages : {
    fr
}});

export default i18n;
