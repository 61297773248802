export interface SearchHelperResult {
    found : boolean,
    searchText : string,
    text : string
}

export interface SearchHelperInstance{
    exec : (text : string) => SearchHelperResult
}

class SearchHelper {
    GetSearchTermFunc(search : string) : SearchHelperInstance{
        const searchAll = search
            .replace(/[eéèëêê]/gi, `[eéèëêê]`)
            .replace(/[aâàä]/gi, `[aâàä]`)
            .replace(/[uùüû]/gi, `[uùüû]`)
            .replace(/[oôö]/gi, `[oôö]`)
            .replace(/[iïî]/gi, `[iïî]`);

        const searchAllSplit = searchAll.split(` `);

        return {
            exec: function (text : string) : SearchHelperResult {
                if(!text)
                    return{
                         found : false,
                         searchText : text,
                         text : text
                    };
                    
                let orginalText = text;
                let found = false;
                
                for (let i = 0; i < searchAllSplit.length; i++) {
                    if (searchAllSplit[i] === ``)
                        continue;
                    
                    const safeSearch = searchAllSplit[i].replace(/\+/gi, '\\+').replace(/\*/gi, '\\*');

                    const regSearch = new RegExp(`[_ ,-/(''''](${safeSearch})`, "gi");
                    const regSearchResult = regSearch.exec(text);
                    if (regSearchResult !== null) {
                        text = text.replace(regSearch, `\0${regSearchResult[0]}\v`);
                        found = true;
                        continue;
                    }
                    const regSearchFirst = new RegExp(`(${safeSearch})`, "gi");
                    const regSearchFirstResult = regSearchFirst.exec(text);
                    if (regSearchFirstResult !== null) {
                        text = text.replace(regSearchFirst, `\0${regSearchFirstResult[0]}\v`);
                        found = true;
                        continue;
                    }
                }
                if (text !== undefined && text !== null) {
                    text = text.replace(/\0/gi, `<span class="term">`);
                    text = text.replace(/\v/gi, `</span>`);
                }

                return {
                    found : found,
                    searchText : text,
                    text : orginalText
                };
            }
        }
    }
}

export default new SearchHelper();