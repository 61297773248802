import { Guid } from '~/@core/guid';
import { GeoLocationApiModel } from '~/@api/runLinkedApi/models/geoLocationApiModels';
import { PaginatedSearchRequest } from '~/@core/types/searchTypes';

export type MemberApiModel = {
    memberId : Guid;
    email : string;
    firstName : string;
    lastName : string;
    userName : string;
    pictureUrl? : string;
}

export type DetailedMemberApiModel = MemberApiModel & {
    pictureDataUrl? : string;
    speed? : number;
    distance? : number;
    geoLoc? : GeoLocationApiModel;
    outsideInvite? : boolean;

    visibility : MemberVisibilityEnum;
    measureSystem : MeasureSystemEnum;
    speedUnit : SpeedUnitEnum;
    description : string;
}

export enum MemberLinkStatusEnum {
    Undefined = 0,
    Linked = 1, 
    InvitingPending = 2, 
    InvitedPending = 3,
    Refused = 4,
}

export enum MeasureSystemEnum
{
    Undefined = 0,
    Metric = 1,
    Imperial = 2,
}

export enum MemberVisibilityEnum
{
    Undefined = 0,
    Public = 1,
    Private = 2,
    Links = 3,
}

export enum SpeedUnitEnum
{
    Undefined = 0,
    DistancePerDuration = 1,
    DurationPerDistance = 2,
}

export type MemberListItemApiModel = MemberApiModel & {
    invitationToken? : string;
    status : MemberLinkStatusEnum;
}

export type MemberPaginatedSearchRequest = PaginatedSearchRequest<{ linkedOnly? : boolean }>
{   
}