import { CachedPromiseKeyReference } from "~/@references/appReference";
import logManager from "./logManager";


// class CacheablePromise<T> extends Promise<T> {

//   private _fromCache : boolean;
//   public get value() : boolean {
//     return this._fromCache;
//   }

//   constructor(promise : Promise<T>, fromCache: boolean){
//     super((resolve, reject) => {
//         promise.then(m => resolve(m), e => reject(e))
//     });
//     this._fromCache = fromCache
//   }
// }

class CacheManager {

    private __cachedPromises = {} as any;

    public async getCachedPromise<T, U>(key : CachedPromiseKeyReference, promise : () => Promise<T>) : Promise<U>
    public async getCachedPromise<T, U>(key : CachedPromiseKeyReference, keySpec : string, promise : () => Promise<T>) : Promise<U>
    public async getCachedPromise<T, U>(key : CachedPromiseKeyReference, promise : () => Promise<T>, adapter : (response : T) => U) : Promise<U>
    public async getCachedPromise<T, U>(key : CachedPromiseKeyReference, keySpec : string, promise : () => Promise<T>, adapter? : (response : T) => U) : Promise<U>
    public async getCachedPromise<T, U>(param1, param2, param3?, param4?) : Promise<U>{

        const key = param1 as CachedPromiseKeyReference;
        const keySpec = (typeof param2 === 'string') ? param2 : null;
        const promise = (!param3) ? param2 : param3;
        const adapter = (param4) ? param4 : param3;

        const fullKey = keySpec ? `${key}_${keySpec}` : key;


        if(this.isCached(key, keySpec)) {
            logManager.info('CacheManager.getCachedPromise', `From cache key "${fullKey}"`);
            return this.__cachedPromises[fullKey];
        }

        logManager.warning('CacheManager.getCachedPromise', `No cache! Renew key "${fullKey}"`);
        this.__cachedPromises[fullKey] = promise().then(
            async response => {
                const result = adapter ? adapter(response) : response;
                this.__cachedPromises[fullKey] = Promise.resolve(result);

                return result;
            }
        ).catch(e => {
            logManager.error('CacheManager.getCachedPromise', `Cache Error! key "${fullKey}"`);
            return Promise.reject(e);
        });

        return this.__cachedPromises[fullKey];
    }

    public isCached(key? : CachedPromiseKeyReference, keySpec? : string) : boolean {
        const fullKey = keySpec ? `${key}_${keySpec}` : key;
        return this.__cachedPromises[fullKey]
    }

    public async clearCachedPromise(key? : CachedPromiseKeyReference, keySpec? : string) : Promise<void> {

        const fullKey = key ? (keySpec ? `${key}_${keySpec}` : key) : null;

        if(fullKey && !this.__cachedPromises[fullKey]) {
            logManager.warning('CacheManager.clearCachedPromise', `Cache key "${fullKey}" not found!`);
            return;
        }

        if(fullKey) {
            logManager.warning('CacheManager.clearCachedPromise', `Clearing cache key "${fullKey}"`);
            delete this.__cachedPromises[fullKey];
            return;
        }

        logManager.warning('CacheManager.clearCachedPromise', `Clearing all cache`);
        this.__cachedPromises = {};
    }
}

export default new CacheManager();
