import { RunLinkedApi } from '~/@api/runLinkedApi';
import { DetailedMemberApiModel, MemberListItemApiModel, MemberPaginatedSearchRequest } from '~/@api/runLinkedApi/models/memberApiModels';
import { MemberProfileApiModel } from '~/@api/runLinkedApi/models/memberProfileApiModels';
import runlinkedApiReference from '~/@api/runLinkedApi/runlinkedApiReference';
import { Guid } from '~/@core/guid';
import ServiceBase from '~/@core/serviceBase';
import { PaginatedSearchRequest, SearchResult } from '~/@core/types/searchTypes';
import cacheManager from '~/@managers/cacheManager';
import logManager from '~/@managers/logManager';
import memberModels from '~/@viewModels/memberViewModels';
import memberModelsMapper, { MemberListItemViewModel, DetailedMemberViewModel } from '~/@viewModels/memberViewModels';

class MemberService extends ServiceBase {
    private _runlinkedApi = new RunLinkedApi();

    public async getProfile(fromCache : boolean = true) : Promise<DetailedMemberViewModel> { 

        const runlinkedApiClient = await this._runlinkedApi.getApiClient();    
        if(!fromCache)   
            await cacheManager.clearCachedPromise('userProfile');

        return this.asCachedServicePromise<MemberProfileApiModel, DetailedMemberViewModel>('userProfile', undefined, 
            () => runlinkedApiClient.get<MemberProfileApiModel>(runlinkedApiReference.Routes.profile.get), 
            response => {
                const result = memberModelsMapper.map_DetailedMemberApiModel_to_DetailedMemberViewModel(response.result.member);
                logManager.debug('MemberService.getProfile', result );
                return result;
            }
        );
    }

    public async getMember(memberId : Guid) : Promise<DetailedMemberViewModel>{

        const runlinkedApiClient = await this._runlinkedApi.getApiClient();

        return this.asServicePromise(runlinkedApiClient.get<DetailedMemberApiModel>(runlinkedApiReference.Routes.member.get(memberId)), 
            response => memberModelsMapper.map_DetailedMemberApiModel_to_DetailedMemberViewModel(response.result)
        );
    }

    public async invite(emails : string[], ids : Guid[]) : Promise<boolean>{

        const runlinkedApiClient = await this._runlinkedApi.getApiClient();

        const request = {
            emails : emails,
            ids : ids
        };

        return this.asServicePromise(runlinkedApiClient.post<void>(runlinkedApiReference.Routes.member.invite.post, request),
            response => true
        );
    }

    public async searchLinkList(request : PaginatedSearchRequest, linkedOnly : boolean = true, specCache : boolean = false, fromCache : boolean = true) : Promise<SearchResult<MemberListItemViewModel>> { 

        const runlinkedApiClient = await this._runlinkedApi.getApiClient();

        const memberSearchRequest : MemberPaginatedSearchRequest = {
            ...request,
            extra : {
                linkedOnly : linkedOnly
            }
        };
        
        let cacheKeySpec = specCache ? `search${linkedOnly ? '_LinkedOnly' : ''}_${request.search}_${request.page}` : null;
        
        if(!fromCache)   
            await cacheManager.clearCachedPromise('linkList', cacheKeySpec);
            
        return this.asCachedSearchServicePromise('linkList', cacheKeySpec,
            () => runlinkedApiClient.post<SearchResult<MemberListItemApiModel>>(runlinkedApiReference.Routes.member.list.post, memberSearchRequest), 
            response => memberModels.map_MemberListItemApiModel_to_MemberListItemViewModel(response)
        );
    }  
        
    public async searchMemberLinkList(memberId : Guid, request: PaginatedSearchRequest, fromCache : boolean = true): Promise<SearchResult<MemberListItemViewModel>> {
        const runlinkedApiClient = await this._runlinkedApi.getApiClient();

        const memberSearchRequest : MemberPaginatedSearchRequest = {
            ...request,
            extra : {
                linkedOnly : true
            }
        };

        if(!fromCache)
            cacheManager.clearCachedPromise('memberLinkList', memberId.toString());

        return this.asCachedSearchServicePromise('memberLinkList', memberId.toString(), 
            () => runlinkedApiClient.post<SearchResult<MemberListItemApiModel>>(runlinkedApiReference.Routes.member.list.post, memberSearchRequest), 
            response => memberModels.map_MemberListItemApiModel_to_MemberListItemViewModel(response)
        );
    }
}

export default new MemberService();
