import { KeyOf } from '~/@core/types/commonTypes';
import { FilterKvp } from '~/@core/types/searchTypes';
import { SpeedUnitViewType } from '~/@viewModels/memberViewModels';

//#region -> ROUTES /////////////////////////////////////////////////////////
export class ExternalRouteKeyReferences {
}
export type ExternalRouteKeyReference = KeyOf<ExternalRouteKeyReferences>
  
export type RouteKeyReferences = {
    auth : {
      login,
      oAuthComplete,
      signUp,
      emailConfirm,
      forgottenPassword,
      passwordReset
    },

    invitation : {
      access
    },

    member : {
      publicPage,
      profile,
      groups,
    },

    group : {
        groups,
        publicPage
    }

    misc : {
        about,
        privacy
    },

    error : {
        notFound,
        forbidden
    }

    landing,
    home, 
    activities,
    profile,
    links,
    notifications
};
export type RouteKeyReference = KeyOf<RouteKeyReferences>;
//#endregion

//#region -> CONFIG //////////////////////////////////////////////////////////
export type ConfigKeyReferences = {
    apiUri,
    appBasePath,
    apiGouvGeoUri,
    googleAnalyticId,
    oneSignalAppId
}
export type ConfigKeyReference = KeyOf<ConfigKeyReferences>;
//#endregion

//#region -> CACHE ///////////////////////////////////////////////////////////
export type CachedPromiseKeyReferences = {
    externalLogins,
    userProfile,

    linkList,
    memberLinkList,
    groupMemberList,
    activityMemberList,
    
    activityList,
    memberActivityList,
    groupActivityList,

    groupList,

    notificationList
};
export type CachedPromiseKeyReference = KeyOf<CachedPromiseKeyReferences>;
//#endregion

//#region -> STORAGE ///////////////////////////////////////////////////////////
type StorageKeyReferences = {
    correlationId,
    session
}
export type StorageKeyReference = KeyOf<StorageKeyReferences>;
//#endregion

//#region -> NATIVE ///////////////////////////////////////////////////////////
export type NativePlatformType = 'ios' | 'android';
//#endregion


class AppReference {

    public ROOT_EMIT_PROFIL_CHANGED : string = 'app::profil-changed';

    /*
    * 10km/h -> 10km -> 60min -----> 60 / 10 = 6min/km
    * -------------------------------------------------
    * 6min/km -> 6 * 100 / 60 = 10 km/h
    */
    public convertSpeed(speed : number | number[], speedUnit : SpeedUnitViewType, suffix : boolean = false) {
        
        const speedList = Array.isArray(speed) ? speed : [speed];
        let resultList = null

        if(speedUnit === 'durationPerDistance') {
            resultList = speedList.map(m => {
                if(m === 0)
                    return 0;

                const val = Math.round((60 / m) * 100) / 100;
                const valSplit = val.toString().split('.');

                const unit = valSplit[0]
                const precision = (valSplit.length > 1 ? valSplit[1] : '').padEnd(2, '0');

                return suffix ? `${unit}:${precision}` : val;
            });
        }
        else {
            resultList = speedList.map(m => suffix ? `${m}km/h`: m);
        }

        return Array.isArray(speed) ? resultList : resultList[0];
    }

    public getSpeedReferences(speedUnit : SpeedUnitViewType) : FilterKvp[] {

        const ref = [];

        for(let i = 5; i < 25; i++){
            // ref.push({ text : this.convertSpeed(i, speedUnit, true), value : i.toString()} as FilterKvp)
            const text = speedUnit === 'durationPerDistance' ? `${this.convertSpeed(i, speedUnit, true)} (${i}km/h)` : `${i}km/h`;
            ref.push({ text : text, value : i.toString()} as FilterKvp)
        }

        return ref;
    }

    public getDistanceReferences() : FilterKvp[] {

        const ref = [];

        for(let i = 1; i < 100; i++){
            ref.push({ text : `${i}km`, value : i.toString()} as FilterKvp)
        }

        return ref;
    }

    public getVersionAscii(version : string, html : boolean = false) 
    {
        var text = `
    ____              __    _       __            __
   / __ \\__  ______  / /   (_)___  / /_____  ____/ /
  / /_/ / / / / __ \\/ /   / / __ \\/ //_/ _ \\/ __  / 
 / _, _/ /_/ / / / / /___/ / / / / ,< /  __/ /_/ /  
/_/ |_|\\__,_/_/ /_/_____/_/_/ /_/_/|_|\\___/\\__,_/   v${version}`;

        if(html)
            return text.replace(/\n/gmi, '<br/>').replace(/\s/gmi, '&nbsp;');

        return text;
    }
}

export default new AppReference();