import { Emitter, createNanoEvents } from 'nanoevents'
import logManager from './logManager';

export interface EventsMap {
    [event: string]: any
  }

  export interface DefaultEvents extends EventsMap {
    [event: string]: (...args: any) => void
  }

  export type Unsubscribe = () => void

export class EventManager<TEvents extends EventsMap = DefaultEvents>
{
  protected _emitter: Emitter<TEvents>;
  private readonly _name : string;

  public constructor(name : string) {
    this._emitter = createNanoEvents()
    this._name = name
  }

  public register<K extends keyof TEvents>(event: K, cb: TEvents[K]): Unsubscribe {
    const eventRegistered = this._emitter.on(event, cb)
    logManager.debug('EventManager.register', `[${this._name}]:${event.toString()}`)

    return eventRegistered
  }

  public emit<K extends keyof TEvents>(event: K, ...args: Parameters<TEvents[K]>): void {
    const eventDispatched = this._emitter.emit(event, ...args)
    logManager.debug('EventManager.emit', `[${this._name}]:${event.toString()}`, ...args)

    return eventDispatched
  }
}
