export class Selectable<T> {
  public value : T;
  public selected : boolean;

  constructor(value : T, selected : boolean = false)
  {
      this.value = value;
      this.selected = selected;
  }
}

export class Queryable<TValue = any, TKey extends string | number = number> {
  public readonly Items : Map<TKey, TValue>;

  constructor(items : Map<TKey, TValue> | Array<TValue> = null){
    
    if(items === null)
      this.Items = new Map<TKey, TValue>();
    else if(Array.isArray(items)){      
      this.Items = new Map<TKey, TValue>();
      items.forEach((m,i) => this.Items.set(i as TKey, m));
    }
    else {
      this.Items = items;
    }      
  }

  public get values() : TValue[] { return Array.from<any>(this.Items.values()) }

  //#region -> Count ////////////////////////////////////////////////////////////////////  
  public count(predicate? : (value: TValue) => boolean) : number
  {
    const items = this.toArray();

    if(items.length === 0)
      return 0;

    if(!predicate)
      return items.length;

    const filtered = items.filter(predicate);
    
    return filtered.length;
  }
  //#endregion

  //#region -> First //////////////////////////////////////////////////////////////////// 
  public firstOrDefault(predicate? : (value: TValue) => boolean, defaultValue?: TValue | null) : TValue | null {
    const items = this.toArray();

    if(items.length === 0)
      return defaultValue || null;

    if(!predicate)
      return items[0];

    const filtered = items.filter(predicate);

    if(filtered.length === 0)
      return defaultValue || null;
      
    return filtered[0];
  }

  public first(predicate? : (value: TValue) => boolean) : TValue
  {
    const result = this.firstOrDefault(predicate);

    if(result === null)
      throw Error(`No item found`);

    return result;
  }
  //#endregion

  //#region -> Single ////////////////////////////////////////////////////////////////////  
  public singleOrDefault(predicate : (value: TValue) => boolean, defaultValue?: TValue | null) : TValue | null {
    const items = this.toArray();

    if(items.length === 0)
      return defaultValue || null;

    const filtered = items.filter(predicate);

    if(filtered.length !== 1)
      return defaultValue || null;
      
    return filtered[0];
  }

  public single(predicate? : (value: TValue) => boolean) : TValue
  {
    const result = this.singleOrDefault(predicate);

    if(result === null)
      throw Error(`No item found`);

    return result;
  }
  //#endregion
  
  //#region -> Any ////////////////////////////////////////////////////////////////////   
  public any(predicate : (value: TValue) => boolean) : boolean {
    const items = this.toArray();

    if(items.length === 0)
      return false;
      
    return items.some(predicate);
  }
  //#endregion

  //#region -> Where ////////////////////////////////////////////////////////////////////   
  public where(predicate : (value: TValue) => boolean) : Queryable<TValue, TKey>
  {
    const items = this.values;

    if(items.length === 0)
      return new Queryable<TValue, TKey>();
        
    const filteredItems = new Map<TKey,TValue>();  
    
    for (let [key, value] of this.Items) {
      if(predicate(value))
        filteredItems.set(key, value);
    }

    return new Queryable<TValue, TKey>(filteredItems);
  }
  //#endregion
  
  //#region -> Select ////////////////////////////////////////////////////////////////////  
  public select<TSelectValue>(predicate : (value: TValue, key? : TKey) => TSelectValue) : TSelectValue[] {
    const items = this.toArray();

    if(items.length === 0)
      return [];
      
    let result : TSelectValue[] = [];
    this.Items.forEach((v, k) => result.push(predicate(v, k)));
    
    return result;
  }

  public selectMany<TSelectValue>(predicate : (value : TValue) => TSelectValue[]) : TSelectValue[] {
    const items = this.toArray();

    if(items.length === 0)
      return [];

    let manyItems : TSelectValue[] = [];

    items.forEach(m => {
      manyItems.push(...predicate(m));
    });

    return manyItems;    
  }

  public forEach(func : (value : TValue, key : TKey) => void) : void {
    this.Items.forEach(func);
  }
  //#endregion

  //#region -> Others //////////////////////////////////////////////////////////////////// 
  public toArray() : TValue[]{
    return this.values;
  }
  
  public toObject() : any {
    let obj = {};

    this.Items.forEach((v, k) => obj[k.toString()] = v);
    return obj;
  }
  //#endregion

  //#region -> Order ///////////////////////////////////////////////////////////////////
  public orderBy<TType>(predicate : (TValue) => TType) : TValue[]{
    const items = this.toArray();

    if(items.length === 0)
      return [];

    return items.sort((a, b) => {
      const pa = predicate(a);
      const pb = predicate(b);
        
      if(typeof(pa) !== 'string'){
        if (pa < pb) return -1;
        if (pa > pb) return 1;
        return 0;
      }
        
      return pa.localeCompare(pb as any);      
    });
  }

  public orderByDesc<TType>(predicate : (TValue) => TType) : TValue[]{
    const items = this.toArray();

    if(items.length === 0)
      return [];

    return items.sort((a, b) => {
      const pa = predicate(a);
      const pb = predicate(b);
        
      if(typeof(pa) !== 'string'){
        if (pa > pb) return -1;
        if (pa < pb) return 1;
        return 0;
      }
      
      return pa.localeCompare(pb as any);     
    });
  }
  //#endregion
}

export class Collection<TValue> extends Queryable<TValue, number> {  
  constructor(items : TValue[] = []){
    super(items);
  }  

  //#region -> Add ////////////////////////////////////////////////////////////////////
  public add(...items : TValue[]){
    const lastIndex = this.Items.size - 1;

    if(Array.isArray(items))
      items.forEach((m,i) => this.Items.set(lastIndex + i as number, m));
    else
      this.Items.set(lastIndex + 1, items);
  }
  //#endregion

  //#region -> Remove ////////////////////////////////////////////////////////////////////
  public remove(predicate : (value : TValue) => boolean) : boolean {
    const index = this.toArray().findIndex(predicate);

    if(index === -1) 
      return false;

     this.Items.delete(index);
    return true;
  }
  //#endregion
}