import logManager from "./logManager";
import storageManager from "./storageManager";


export class SessionManager {

    constructor() {
    }

    public async getActiveSession(): Promise<TokenInfo<SessionTokenData>> {
        const sessionValue = await storageManager.get<string>('session');

        if (!sessionValue)
            return null;

        return {
            token: sessionValue,
            data: null,
            rawValue: sessionValue
        };
    }

    public async setSession(token: string): Promise<void> {
        await storageManager.set('session', token);
        
        logManager.debug('SessionManager.setSession', 'session active', token);
    }

    public async clearActiveSession(): Promise<void> {
        await storageManager.remove('session');
    }

    //#region -> PRIVATES
    /////////////////////////////////////////////////////////////////  
    private getInfoFromToken<T>(tokenRawValue: string): TokenInfo<T> {
        const base64Url = tokenRawValue.split('.')[1];
        const tokenSplit = tokenRawValue.split('$');
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonStr = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

        return {
            rawValue: tokenRawValue,
            expires: tokenSplit.length > 1 ? new Date(tokenSplit[1]) : null,
            token: tokenSplit[0],
            data: JSON.parse(jsonStr) as T
        };
    }
    //#endregion
}

export default new SessionManager();

//#region -> TYPES ////////////////////////////////////////////////////////////////////////
export type TokenInfo<T> = {
    rawValue: string;
    expires?: Date;
    token: string;
    data: T;
}

export type TokenData = {
    iat: number;
    exp: number;
}

export type SessionTokenData = TokenData & {
    userId: string;
    type: string;
}
//#endregion
