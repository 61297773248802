import configManager from "./configManager";
import logManager from "./logManager"
import cacheManager from './cacheManager';
import navigationManager from './navigationManager';
import storageManager from "./storageManager";
import { EventManager } from "./eventManager";

let __initialized: boolean = false;

interface PushAddionalData {
    redirectUrl?: string;
    [key: string]: any;
}

export type WebPushNotification = {
    additionalData : PushAddionalData
}


export type WebPushStatus = {
    correlationId : string,
    userId : string,
    permission: boolean
}

class WebPushManager {
    public didInit: boolean = false;
    public permission: boolean = false;

    private _eventManager: EventManager<{
        notification : (notification : WebPushNotification) => Promise<void>,
        notificationClick : (notification : WebPushNotification) => Promise<void>,
        notificationDismiss : (notification : WebPushNotification) => Promise<void>,
        pushEnabled : (newSubscription: boolean) => Promise<void>,
        pushDisabled : () => Promise<void>,
        newSubscription: (status : WebPushStatus) => Promise<void>
    }> = null;

    constructor() {
        this._eventManager = new EventManager("WebPushManager");
    }

    public async initialize() {
        if (__initialized)
            return;

        await this.oneSignalInit();

        __initialized = true;
    }

    public onNotification(fn: (notification : WebPushNotification) => Promise<void>) {
        this._eventManager.register('notification', fn)
    }
    public onNotificationClick(fn: (notification : WebPushNotification) => Promise<void>) {
        this._eventManager.register('notificationClick', fn)
    }

    public onPushEnabled(fn: (newSubscription : boolean) => Promise<void>) {
        this._eventManager.register('pushEnabled', fn)
    }

    public onPushDisabled(fn: () => Promise<void>) {
        this._eventManager.register('pushDisabled', fn)
    }

    public onNewSubscription(fn: (correlation : WebPushStatus) => Promise<void>) {
        this._eventManager.register('newSubscription', fn)
    }

    public getStatus() : WebPushStatus {
        return {
            correlationId : window.OneSignal.User.PushSubscription.id,
            userId : (window.OneSignal.User as any)._currentUser.id,
            permission : this.permission
        }
    }

    public async optIn(): Promise<WebPushStatus> {

        const permission = await window.OneSignal.Notifications.permission;
        const correlationId = window.OneSignal.User.PushSubscription.id;

        return new Promise((resolve, reject) => {
            if(permission && correlationId){
                logManager.debug('WebPushManager.optIn', 'Push notification already subscribed', window.OneSignal.User.PushSubscription);

                this._eventManager.emit('pushEnabled', false);
            }
            else if(correlationId) {
                window.OneSignal.Notifications.requestPermission().then(
                    async () => {
                        this.permission = await window.OneSignal.Notifications.permission;

                        if(this.permission) {
                            this._eventManager.emit('pushEnabled', true);
                            this._eventManager.emit('newSubscription', this.getStatus());
                        }
                        else {
                            this._eventManager.emit('pushDisabled');
                        }
                });
            }

            else {
                window.OneSignal.User.PushSubscription.optIn().then(
                    async (response) => {
                        this.permission = await window.OneSignal.Notifications.permission;

                        if (!this.permission) {
                            reject(new Error('Push notification not allowed'));
                            this._eventManager.emit('pushDisabled');
                        }

                        logManager.debug('WebPushManager.optIn', 'Push notification allowed', window.OneSignal.User.PushSubscription);

                        this._eventManager.emit('newSubscription', this.getStatus());
                        resolve(this.getStatus());
                        this._eventManager.emit('pushEnabled', true);
                    }
                );
            }
        });
    }

    public async optOut(): Promise<boolean> {

        return window.OneSignal.User.PushSubscription.optOut().then(
            async (response) => {
                logManager.debug('WebPushManager.optOut', 'Push notification disabled');

                this.permission = await window.OneSignal.Notifications.permission;
                this._eventManager.emit('pushDisabled');

                return true;
            }
        );
    }

    private async oneSignalInit() {

        // Onesignal SDK documentation : https://documentation.onesignal.com/docs/web-sdk
        window.OneSignalDeferred = window.OneSignalDeferred || [];
        window.OneSignalDeferred.push((OneSignal) => {

            OneSignal.Debug.setLogLevel("error");

            const initOptions = {
                appId: configManager.getConfig('oneSignalAppId'),
                safari_web_id: "web.onesignal.auto.32a023df-3e37-4c19-843f-3978a63a946e",
                notifyButton: {
                    enable: false
                },
                allowLocalhostAsSecureOrigin: true,
                notificationClickHandlerAction : 'focus'
            };

            OneSignal.init(initOptions).then(
                async (response) => {

                    this.permission = await window.OneSignal.Notifications.permission;

                    logManager.debug('WebPushManager.oneSignalInit', 'initialized', OneSignal.User.PushSubscription, (OneSignal.User as any)._currentUser);

                    (OneSignal as any).context.workerMessenger.on('notification.willDisplay', (notificationReceivedEvent) => {
                        const notification = notificationReceivedEvent.notification;

                        logManager.debug('WebPushManager:notification.willDisplay', 'handling push coming', notification);
                        OneSignal.Notifications.setDefaultUrl(window.location.href);

                        this._eventManager.emit('notification', notification);
                    });

                    (OneSignal as any).context.workerMessenger.on('notification.clicked', (openedEvent) => {
                        const notification = openedEvent.notification;
                        const additionalData = notification.additionalData as PushAddionalData;

                        logManager.debug('WebPushManager:notification.clicked', 'handling push press event', notification, additionalData);

                        this._eventManager.emit('notificationClick', notification);
                    })
                },
                error => {
                    logManager.error("WebPushManager.oneSignalInit", "error on init", error)
                });
        });
    }
}

export default new WebPushManager();
