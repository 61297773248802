class JsonHelper {
    
    public isJsonString(str : string) : boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public clone<T>(obj : T) : T {
        return JSON.parse(JSON.stringify(obj));
    }

    
    public serialize<T>(obj : T) : string {
        return JSON.stringify(obj);
    }

    public deserialize<T>(objStr : string) : T {
        return JSON.parse(objStr) as T;
    }
}
export default new JsonHelper();