import { Dictionary } from "~/@core/dictionary";
import { TupleReference } from "~/@core/tupleReference";

class ThemeReference {
    public colorVariants = TupleReference (
        'gray'
        , 'grayblue'
        , 'orange'
        , 'red'
        , 'pink'
        , 'purple'
        , 'indigo'
        , 'blue'
        , 'teal'
        , 'green'
        , 'yellow'
        , 'brown'
        , 'primary'
        , 'secondary' 
        , 'success' 
        , 'danger'
        , 'info'  
        , 'warning'
        , 'dark'
        , 'light'
    );

    public $gray : string = '#9C9896';
    public $grayblue : string = '#8394AF';
    public $orange : string = '#FF5B0F';
    public $red : string = '#FE3E45';
    public $pink : string = '#FF61C0';
    public $purple : string = '#D652FE';
    public $indigo : string = '#868EFE';
    public $blue : string = '#3F9EFD';
    public $teal : string = '#0DD3AF';
    public $green : string = '#22CE47';
    public $yellow : string = '#F4AD1F';
    public $brown : string = '#EA7E0B';
    public $dark : string = '#1A1B1D';
    public $light : string = '#FEFEFE';

    public colorHexByColorVariant : Dictionary<ThemeColorVariant, string> = new Dictionary(new Map([
        ['gray', this.$gray],
        ['grayblue', this.$grayblue],
        ['orange', this.$orange],
        ['red', this.$red],
        ['pink', this.$pink],
        ['purple', this.$purple],
        ['indigo', this.$indigo],
        ['blue', this.$blue],
        ['teal', this.$teal],
        ['green', this.$green],
        ['yellow', this.$yellow],
        ['brown', this.$brown],
        ['primary', this.$orange], 
        ['secondary', this.$grayblue], 
        ['success', this.$green],
        ['danger', this.$red],
        ['info', this.$blue],
        ['warning', this.$yellow],
        ['light', this.$light],
    ]));
    
    public colorNuances = TupleReference(
        'darkest'
        , 'darken'
        , 'darker'
        , 'dark'
        , 'light'
        , 'lighter'
        , 'lighten'
        , 'lightest'
    );
}

const themeReference = new ThemeReference();

export type ThemeColorVariant = typeof themeReference.colorVariants[number];
export type ThemeColorNuance = typeof themeReference.colorNuances[number];

export default themeReference;


