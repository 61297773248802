import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import jsonHelper from "~/@helpers/jsonHelper";

export class ApiClient {

    private readonly _apiClient: AxiosInstance = null;
    private readonly _baseUrl: string = null;
    private readonly _events : ApiClientEvents = null; 

    private readonly _dataResponseMapper : (response : AxiosResponse) => ApiResponse<any>;
    private readonly _errorMapper : (error : AxiosError, cancelationObj? : CancelationObject) => ApiError;
    // private readonly _streamResponseMapper : (response : AxiosResponse) => Promise<ApiResponse<any>>;

    public get baseUrl(): string {
        return this._baseUrl;
    }

    constructor(baseUrl: string, options: ApiClientOptions) {

        this._events = options.events || {} as any;
        this._baseUrl = baseUrl;

        let axiosOptions: AxiosRequestConfig = {
            baseURL: baseUrl,
            withCredentials: options.withCredentials,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        this._apiClient = axios.create(axiosOptions);

        //#region -> MAPPERS //////////////////////////////////////////////////
        this._dataResponseMapper = options.dataResponseMapper 
            ? options.dataResponseMapper
            : (response : AxiosResponse) => {
                if (response.data === '')
                    return new ApiResponse(true, {}, null);

                if (!response.data)
                    return response.data;

                return response.data.erreur === true
                    ? new ApiResponse(false, null, response.data)
                    : new ApiResponse(true, response.data, null);
            };

        this._errorMapper = options.errorMapper 
            ? options.errorMapper
            : (error : AxiosError, cancelationObj? : CancelationObject) => {                
                if (!error)
                    return new ApiError(0);
    
                if ((error as any).requestCanceled) {
                    return new ApiError(HttpResultStatusEnum.Canceled, error.request, error.message, cancelationObj.code, error);
                }
        
                if (!error.response) {
                    if (error.request)
                        return new ApiError(HttpResultStatusEnum.Fatal, error.request.requestURL, error.message, null, error);
        
                    return new ApiError(HttpResultStatusEnum.Fatal, null, error.message, null, error);
                }
        
                return new ApiError(error.response.status, error.request.requestURL, error.response.data.message, error.response.data.code, error);
            }

        // this._streamResponseMapper = options.streamResponseMapper
        //     ? options.streamResponseMapper
        //     : async (response : AxiosResponse) => {
                
        //     };
        //#endregion

        //#region -> INTERCEPTORS ////////////////////////////////////////////
        this._apiClient.interceptors.request.use(async config => {

            config = this.onRequest(config);

            if (this._events.onRequest)
                config = await this._events.onRequest(config);

            return config;
        });
        //#endregion
    }

    public get<T = any>(url: string): Promise<ApiResponse<T>>
    public get<T = any>(url: string, canceller: Canceler): Promise<ApiResponse<T>>
    public get<T = any>(url: string, config: AxiosRequestConfig): Promise<ApiResponse<T>>
    public get<T = any>(url: string, config: AxiosRequestConfig, canceller: Canceler): Promise<ApiResponse<T>>
    public get<T = any>(param1, param2?, param3?): Promise<ApiResponse<T>> {
        let url: string = this._baseUrl !== null ? `${this._baseUrl}${param1}` : param1;
        let config: AxiosRequestConfig = null;
        let canceler: Canceler = null;

        if (!param2 && !param3) {
        }
        else if (!param3) {
            config = param2.constructor.name !== Canceler.name ? param2 : null;
            canceler = param2.constructor.name === Canceler.name ? param2 : null;
        }
        else {
            config = param2;
            canceler = param3;
        }

        if (canceler) {
            let source = axios.CancelToken.source();

            config = config || {};
            config.cancelToken = source.token;

            (canceler as any).setHook(() => source.cancel(`Operation was canceled by user. route=${url}`));
        }

        return this._apiClient.get(url, config).then(response => this.buildApiResponse(response), error => this.buildApiError(error, canceler));
    }

    public async getFile(url?: string, fileName?: string, config: AxiosRequestConfig = {}): Promise<ApiResponse<Blob>> {
        url = this._baseUrl !== null ? `${this._baseUrl}${url}` : url;

        const default_file_type = 'application/pdf';

        config.responseType = "arraybuffer";
        config.headers = config.headers || {};

        if (!config.headers.Accept)
            config.headers.Accept = default_file_type;

        return this._apiClient.get(url, config).then(
            response => new ApiResponse(true, fileName ? new File([response.data], fileName, { type: default_file_type }) : new Blob([response.data], { type: default_file_type }), null),
            error => this.buildApiError(error)
        );
    }

    public post<T = any>(url: string, data: any): Promise<ApiResponse<T>>
    public post<T = any>(url: string, data: any, config: AxiosRequestConfig): Promise<ApiResponse<T>>
    public post<T = any>(url: string, data: any, canceler: Canceler): Promise<ApiResponse<T>>

    public post<T = any>(url: string, data: any, canceler: Canceler): Promise<ApiResponse<T>>
    public post<T = any>(url: string, data: any, config: AxiosRequestConfig): Promise<ApiResponse<T>>

    public post<T = any>(url: string, data: any, config: AxiosRequestConfig, canceler: Canceler): Promise<ApiResponse<T>>
    public post<T = any>(param1, param2, param3?, param4?): Promise<ApiResponse<T>> {

        let url: string = this._baseUrl !== null ? `${this._baseUrl}${param1}` : param1;
        let data: any = param2;
        let config: AxiosRequestConfig = null;
        let canceler: Canceler = null;

        if (!param3 && !param4) {
        }
        else if (!param4) {
            config = (param3.constructor && param3.constructor.name) !== Canceler.name ? param3 : null;
            canceler = (param3.constructor && param3.constructor.name) === Canceler.name ? param3 : null;
        }
        else {
            data = param2;
            config = param3;
            canceler = param4;
        }

        if (canceler) {
            let source = axios.CancelToken.source();

            config = config || {};
            config.cancelToken = source.token;

            (canceler as any).setCancelTokenSource(source);
        }

        return this._apiClient.post(url, data, config).then(response => this.buildApiResponse(response), error => this.buildApiError(error, canceler));
    }

    public postFile<T = any>(url: string, file: File, fileType = 'application/octet-stream', config: AxiosRequestConfig = {}): Promise<ApiResponse<T>> {
        url = this._baseUrl !== null ? `${this._baseUrl}${url}` : url;

        var formData = new FormData();
        // var file = new File([blob], "file", { type: blob.type });
        formData.append("file", file);

        config.headers = config.headers || {};
        config.headers['Content-Type'] = 'multipart/form-data';

        return this._apiClient.post(url, formData, config).then(response => this.buildApiResponse(response), error => this.buildApiError(error));
    }

    public async postToGetFile(url: string, data?: any, config: AxiosRequestConfig = {}, fileType = 'application/pdf'): Promise<ApiResponse<Blob>> {
        url = this._baseUrl !== null ? `${this._baseUrl}${url}` : url;
        config = config || {};

        config.responseType = "arraybuffer";
        config.headers = config.headers || {};

        if (!config.headers.Accept)
            config.headers.Accept = fileType;

        return this._apiClient.post(url, data, config).then(response => new ApiResponse(true, new Blob([response.data], { type: fileType, }), null), error => this.buildApiError(error));
    }

    public async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
        url = this._baseUrl !== null ? `${this._baseUrl}${url}` : url;

        return this._apiClient.put(url, data, config).then(response => this.buildApiResponse(response), error => this.buildApiError(error));
    }

    public async delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
        url = this._baseUrl !== null ? `${this._baseUrl}${url}` : url;

        return this._apiClient.delete(url, config).then(response => this.buildApiResponse(response), error => this.buildApiError(error));
    }

    protected onRequest(config: AxiosRequestConfig) : AxiosRequestConfig { return config }
    protected onResponse(response : ApiResponse<any>) : void { }
    protected onError(error: ApiError) : void { }

    protected onFunctionalError(error: FunctionalError): void { }
    protected onAlternativeScenario(alternativeScenario: AlternativeScenario) : void { }
    protected onUnAuthorized(error: ApiError) : void { }
    protected onNotFound(error: ApiError) : void { }
    protected onTechnicalError(error: ApiError) : void { }

    //#region -> PRIVATES ////////////////////////////////////////////////////////////////
    

    private async buildApiResponse(response : AxiosResponse, canceler? : Canceler) {
        
        if (canceler) 
            canceler.stop();

        const apiResponse = this._dataResponseMapper(response);

        if (this._events.onResponse)
            this._events.onResponse(apiResponse);

        return Promise.resolve(apiResponse);
    }

    private async buildApiError(error : AxiosError, canceler? : Canceler) : Promise<any> {   
        if (canceler) 
            canceler.stop();

        let apiError : ApiError = null;

        if (error.request === undefined && jsonHelper.isJsonString(error.message)) {
            const cancelationObj = JSON.parse(error.message) as CancelationObject;

            error.message = cancelationObj.message;
            (error as any).requestCanceled = true;

            apiError = await this._errorMapper(error, cancelationObj);
        }
        else {
            apiError = await this._errorMapper(error);
        }

        this.onError(apiError);
        
        if (apiError.status === HttpResultStatusEnum.UnAuthorized || apiError.status === HttpResultStatusEnum.Forbidden) {
            this.onUnAuthorized(apiError);
            if (this._events.onUnAuthorized)
            this._events.onUnAuthorized(apiError);
        }

        if (apiError.status === HttpResultStatusEnum.NotFound) {
            this.onNotFound(apiError);
            if (this._events.onNotFound)
                this._events.onNotFound(apiError);
        }

        if (apiError.status === HttpResultStatusEnum.Fatal) {
            this.onTechnicalError(apiError);
            if (this._events.onTechnicalError)
                this._events.onTechnicalError(apiError);
        }

        return Promise.reject(apiError);
    }
    //#endregion
}

//#region -> TYPES /////////////////////////////////////////////////////////////////////


export enum HttpResultStatusEnum {
    Undefined = 0,
    Canceled = -1,
    Ok = 200,
    BadRequest = 400,
    UnAuthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    NotAcceptable = 406,
    TooManyRequests = 429,
    Fatal = 500,
    Maintenance = 503
}

export type FunctionalError = {
    errorCode: string;
    message: string;
}

export type AlternativeScenario = {
    alternativeResult: boolean;
    code: string;
    message: string;
}

export class ApiError {
    private readonly _status: HttpResultStatusEnum;
    private readonly _message: string;
    private readonly _requestUrl: string;
    private readonly _code: string;
    private readonly _data: string;

    public get status(): HttpResultStatusEnum { return this._status; }
    public get message(): string { return this._message; }
    public get requestUrl(): string { return this._requestUrl; }
    public get code(): string { return this._code; }
    public get data(): any { return this._data; }

    constructor(status: number, requestUrl: string = null, message: string = null, code: string = null, data: any = null) {
        this._status = status;
        this._code = code;
        this._message = message;
        this._requestUrl = requestUrl;
        this._data = data;
    }
}

export class ApiResponse<T> {
    private readonly _success: boolean;
    private readonly _result: T = null;
    private readonly _error: FunctionalError = null

    public get success(): boolean { return this._success; }
    public get result(): T { return this._result; }
    public get error(): FunctionalError { return this._error; }

    constructor(success: boolean, result: T = null, error: FunctionalError = null) {
        this._success = success;
        this._result = result;
        this._error = error;
    }
}

export type CancelationObject = {
    code: string;
    message: string;
}

export class Canceler {
    private source: CancelTokenSource;
    private interval: number;
    private cancelationObj: CancelationObject;

    constructor(time: number, code: string, message?: string) {
        this.cancelationObj = {
            code,
            message: message || `Operation was canceled by user`
        };

        this.interval = setInterval(() => {
            this.cancel();
        }, time)
    }

    private cancel() {
        if (!this.source)
            throw new Error('undefined cancel hook');

        this.source.cancel(JSON.stringify(this.cancelationObj));
    }

    public stop() {
        clearInterval(this.interval);
    }

    private setCancelTokenSource(source: CancelTokenSource) {
        this.source = source;
    }
}

export type ApiClientOptions = {
    dataResponseMapper? : (response : AxiosResponse) => ApiResponse<any>;
    errorMapper? : (error : AxiosError, cancelationObj? : CancelationObject) => ApiError;
    // streamResponseMapper? : (response : AxiosResponse) => Promise<ApiResponse<any>>;

    withCredentials?: boolean;
    events?: ApiClientEvents;
};

export type ApiClientEvents = {
    onRequest?: (config: AxiosRequestConfig) => AxiosRequestConfig,
    onResponse?: (response: ApiResponse<any>) => void,
    onError?: (error: ApiError) => void,
    onFunctionalError?: (error: FunctionalError) => void,
    onAlternativeScenario?: (alternativeScenario: AlternativeScenario) => void,
    onUnAuthorized?: (error: ApiError) => void,
    onNotFound?: (error: ApiError) => void,
    onTechnicalError?: (error: ApiError) => void
}

//#endregion