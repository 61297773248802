import { RouteConfig } from 'vue-router'
import { RouteKeyReference } from '~/@references/appReference';
  
export type RouteDescription = {
    name : RouteKeyReference,
    path : string;
  }
  
  const _landing : RouteDescription = { name : 'landing', path : '/'};
  const _login : RouteDescription = { name : 'auth.login', path : '/login'};
  const _signUp : RouteDescription = { name : 'auth.signUp', path : '/signup'};
  const _oAuthComplete : RouteDescription = { name : 'auth.oAuthComplete', path : '/oauth/complete'};
  const _emailConfirm : RouteDescription = { name : 'auth.emailConfirm', path : '/email/confirm'};
  const _forgottenPassword : RouteDescription = { name : 'auth.forgottenPassword', path : '/password/forgot'};
  const _passwordReset : RouteDescription = { name : 'auth.passwordReset', path : '/password/reset'};
  const _invitationAccess : RouteDescription = { name : 'invitation.access', path : '/invitation/:token'};
  const _home : RouteDescription = { name : 'home', path : '/home'};
  const _activities : RouteDescription = { name : 'activities', path : '/activity/:activityId?'};
  const _profile : RouteDescription = { name : 'profile', path : '/profile'};
  const _links : RouteDescription = { name : 'links', path : '/link'};
  const _groups : RouteDescription = { name : 'group.groups', path : '/group'};
  const _groupPublicPage : RouteDescription = { name : 'group.publicPage', path : '/group/:groupId'};
  const _memberProfile : RouteDescription = { name : 'member.profile', path : '/member/:memberId/profile'};
  const _memberPublicPage : RouteDescription = { name : 'member.publicPage', path : '/member/:memberId'};
  const _notifications : RouteDescription = { name : 'notifications', path : '/notification'};
  const _about : RouteDescription = { name : 'misc.about', path : '/about'};
  const _privacy : RouteDescription = { name : 'misc.about', path : '/privacy'};
  const _forbidden : RouteDescription = { name : 'error.forbidden', path : '/forbidden'};
 
  const routes : Array<RouteConfig> = [
    // -> AUTH //////////////////////////////////////////////////////////
    { name : _landing.name, path: _landing.path, component: () => import('~/views/auth/loginPage.vue'), meta: { requiresAuth : false, title : `RunLinked | Retrouves tes liens et ne courres plus seul!` } },
    { name : _login.name, path: _login.path, component: () => import('~/views/auth/loginPage.vue'), meta: { requiresAuth : false, title : `RunLinked | Connexion` } },
    { name : _signUp.name, path: _signUp.path, component: () => import('~/views/auth/signup.vue'), meta: { requiresAuth : false, title : `Runlinked | Inscription` } },
    { name : _oAuthComplete.name, path: _oAuthComplete.path, component: () => import('~/views/signin/oAuthCompletePage.vue'), meta: { requiresAuth : false, title : `Runlinked | Confirmation` } },
    { name : _emailConfirm.name, path: _emailConfirm.path, component: () => import('~/views/signin/emailConfirmationPage.vue'), meta: { requiresAuth : false, title : `Runlinked | Confirmation` } },
    { name : _forgottenPassword.name, path: _forgottenPassword.path, component: () => import('~/views/auth/forgottenPasswordPage.vue'), meta: { requiresAuth : false, title : `Runlinked | Mot de passe oublié` } },
    { name : _passwordReset.name, path: _passwordReset.path, component: () => import('~/views/auth/passwordResetPage.vue'), meta: { requiresAuth : false, title : `Runlinked | Mot de passe oublié` } },
    
    { name : _invitationAccess.name, path: _invitationAccess.path, component: () => import('~/views/invitation/invitationAccessPage.vue'), meta: { requiresAuth : false, title : `RunLinked | Invitation` } },

    { name : _home.name, path: _home.path, component: () => import('~/views/activity/activitiesPage.vue'), meta: { requiresAuth : true, title : `RunLinked | Les sorties` } },
    { name : _activities.name, path: _activities.path, component: () => import('~/views/activity/activitiesPage.vue'), meta: { requiresAuth : true, title : `Runlinked | Les sorties` } },
    { name : _profile.name, path: _profile.path, component: () => import('~/views/member/profilePage.vue'), meta: { requiresAuth : true, title : `Runlinked | Mon profil` } },
    { name : _links.name, path: _links.path, component: () => import('~/views/link/linksPage.vue'), meta: { requiresAuth : true, title : `Runlinked | Mes contacts` } },
    
    { name : _groups.name, path: _groups.path, component: () => import('~/views/group/groupsPage.vue'), meta: { requiresAuth : true, title : `Runlinked | Les groupes` } },
    { name : _groupPublicPage.name, path: _groupPublicPage.path, component: () => import('~/views/group/groupPage.vue'), meta: { requiresAuth : true, title : `Runlinked | Page groupe` } },
   
    { name : _memberProfile.name, path: _memberProfile.path, component: () => import('~/views/member/profilePage.vue'), meta: { requiresAuth : true, title : `Runlinked | Profil public` } },
    { name : _memberPublicPage.name, path: _memberPublicPage.path, component: () => import('~/views/member/memberPage.vue'), meta: { requiresAuth : true, title : `Runlinked | Page membre` } },
    
    { name : _notifications.name, path: _notifications.path, component: () => import('~/views/notification/notificationsPage.vue'), meta: { requiresAuth : true, title : `Runlinked | Notifications` } },
    { name : _about.name, path: _about.path, component: () => import('~/views/aboutPage.vue'), meta: { requiresAuth : false, title : `Runlinked | A Propos...` } },
    { name : _privacy.name, path: _privacy.path, component: () => import('~/views/privacyPage.vue'), meta: { requiresAuth : false, title : `Runlinked | Règles de confidentialité ` } },

    { name : _forbidden.name, path: _forbidden.path, component: () => import('~/views/error/forbiddenErrorPage.vue'), meta: { requiresAuth : false, title : `Runlinked | Page non autorisé` } },
    
    // -> OTHERS ///////////////////////////////////////////////////////////////
    { path: "*", component: () =>  import('~/views/error/notFoundErrorPage.vue'), meta: { requiresAuth : false, title : `Runlinked | Page introuvable` } }
  ];

export default routes;