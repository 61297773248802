import { AxiosError, AxiosResponse } from "axios";
import { AlternativeScenario, ApiClient, ApiError, ApiResponse, CancelationObject, FunctionalError, HttpResultStatusEnum } from "~/@core/apiClient";
import configManager from "~/@managers/configManager";
import sessionManager from "~/@managers/sessionManager";
import { NativePlatformType } from "~/@references/appReference";

export class RunLinkedApi {

    constructor() {
    }

    public async getApiClient(withCredentials : boolean = false) : Promise<ApiClient>
    {
        const session = await sessionManager.getActiveSession();
        const appVersion = configManager.getVersion();

        const apiClient = new ApiClient(`${configManager.getConfig('apiUri')}`, {
            withCredentials : withCredentials,

            dataResponseMapper : (response : AxiosResponse) => {
                // if (response.data === '')
                //     return new ApiResponse(true, {}, null);

                if(response.data?.success === undefined && response.data?.result === undefined)
                    return new ApiResponse(true, response.data, null);

                return !response.data.success
                    ? new ApiResponse(false, null, { errorCode : response.data.result, message : response.data.message } as FunctionalError)
                    : new ApiResponse(true, response.data.result, null);
            },

            errorMapper : (error : AxiosError, cancelationObj? : CancelationObject) => {

                if (!error)
                    return new ApiError(0);
    
                if ((error as any).requestCanceled) {
                    return new ApiError(HttpResultStatusEnum.Canceled, error.request.requestURL, cancelationObj.message, cancelationObj.code, error);
                }

                if (!error.response) {
                    if (error.request)
                        return new ApiError(HttpResultStatusEnum.Fatal, error.request.requestURL, error.message, null, error);
        
                    return new ApiError(HttpResultStatusEnum.Fatal, null, error.message, null, error);
                }
        
                if(error.response.data) {
                    const data = error.response.data;
                    if(data.error_description)
                        return new ApiError(error.response.status, error.request.requestURL, data.error_description, data.error);
                }

                return new ApiError(error.response.status, error.request.requestURL, error.response.data.message, error.response.data.code, error);
            },


            events : {
                onRequest : config => {       
                    
                    config.headers['appVersion'] = configManager.getVersion();
                    const platform = configManager.getNativePlatform();
                    
                    if(platform)
                        config.headers['appPlatform'] = platform;

                    if(session !== null )
                        config.headers['Authorization'] = `Bearer ${session.token}`;

                    return config;
                },
                
                onResponse : (response) => {
                    return response;
                },
                
                onUnAuthorized : async error => {
                    return error;
                }
            }
        });

        return apiClient;
    }   
}