import { AppError, AppErrorType } from "~/@core/types/errorTypes";
import  { SearchResult } from '~/@core/types/searchTypes';
import searchHelper from '~/@helpers/searchHelper';
import commonModelMapper from '~/@viewModels/commonViewModels';
import cacheManager from "~/@managers/cacheManager";
import { ApiError, ApiResponse, HttpResultStatusEnum } from "./apiClient";
import logManager from "~/@managers/logManager";
import { CachedPromiseKeyReference } from "~/@references/appReference";
import sessionManager from "~/@managers/sessionManager";

export default abstract class ServiceBase {

    //#region -> SERVICE METHODS //////////////////////////////////////////////////////////////////////
    protected asServicePromise<T, U>(apiPromise : Promise<ApiResponse<T>>, response? : (response : ApiResponse<T>) => U){
        return apiPromise.then(
            apiResponse => this.resolve<U>(apiResponse, response),
            error => this.reject<U>(error)
        )
    }

    protected async asCachedServicePromise<T, U>(key : CachedPromiseKeyReference, keySpec : string, apiPromise : () => Promise<ApiResponse<T>>, adapter? : (response : ApiResponse<T>) => U) : Promise<U>{
        return cacheManager.getCachedPromise<U, U>(
            key,
            keySpec,
            () => this.asServicePromise<T, U>(apiPromise(), apiResponse => (adapter ? adapter(apiResponse) : apiResponse.result) as U)
        )
        .catch((error : AppError) => {
            logManager.error('ServiceBase.asCachedServicePromise', error.message, error)
            return null;
        });
    }


    protected async asSearchServicePromise<T, U>(apiPromise : Promise<ApiResponse<SearchResult<T>>>, adapter : (item : T) => U) : Promise<SearchResult<U>> {
        return this.asServicePromise(apiPromise, response => commonModelMapper.map_SearchResultApiModel_to_SearchResultViewModel(response.result, adapter));
    }

    protected async asCachedSearchServicePromise<T, U>(key : CachedPromiseKeyReference, keySpec : string, apiPromise : () => Promise<ApiResponse<SearchResult<T>>>, adapter : (item : T) => U) : Promise<SearchResult<U>>{
        return cacheManager.getCachedPromise<SearchResult<U>, SearchResult<U>>(
            key,
            keySpec,
            () => this.asServicePromise<SearchResult<T>, SearchResult<U>>(apiPromise(), response => commonModelMapper.map_SearchResultApiModel_to_SearchResultViewModel(response.result, adapter))
        )
        .catch((error : AppError) => {
            logManager.error('ServiceBase.asCachedSearchServicePromise', error.message, error)
            return null;
        });
    }

    protected async search<T>(dataPromise : Promise<Array<T>>, search: string = '', searchLabel : (item : T) => string = null) : Promise<Array<T>> {
        const searchTermFunc = searchHelper.GetSearchTermFunc(search);

        return dataPromise.then(
            response => {
                const types = search && search.length > 0
                    ? response.filter(m => searchTermFunc.exec(searchLabel(m)).found)
                    : response;

                return types;
            }
        );
    }
    //#endregion

    protected async resolve<TResult>(response : ApiResponse<any>, adapter? : (response : ApiResponse<any>) => TResult) : Promise<TResult>{
        // if(response.success){
        //     try
        //     {
        //         return Promise.resolve(adapter ? adapter(response) : response.result).catch((error) => {
        //             logManager.error('core.serviceBase', error, response);
        //             return Promise.reject( { type : 'technical', code : "API_ERROR", message : error.message } as AppError);
        //         });
        //     }
        //     catch(error)
        //     {
        //         logManager.error('core.serviceBase', error, response);
        //     }
        // }

        // //TODO : (JES) -> THIS MUST BE REVIEWED FOR SPECIFIC IMPLEMENTATION
        //     return Promise.reject( { type : 'functional', code : response.error.errorCode, message : response.error.message, data : response.result } as AppError)

        if(response.error)
            return Promise.reject( { type : 'functional', code : response.error.errorCode, message : response.error.message, data : response.result } as AppError)

        return Promise.resolve(adapter ? adapter(response as any) : response as any).catch((error) => {
            logManager.error('core.serviceBase', error, response);
            return Promise.reject({ type : 'technical', code : "API_ERROR", message : error.message } as AppError);
        });
    }

    protected async reject<TResult>(error : ApiError = null) : Promise<TResult>{
        let type : AppErrorType = 'technical';

        const session = await sessionManager.getActiveSession();

        if(error.status === HttpResultStatusEnum.Forbidden || (error.status === HttpResultStatusEnum.UnAuthorized && !!session)) type = 'notAllowed';
        else if (error.status === HttpResultStatusEnum.UnAuthorized) type = 'sessionRequired';
        else if (error.status === HttpResultStatusEnum.Maintenance) type = 'maintenance';
        else if (error.status === HttpResultStatusEnum.Fatal || error.status === HttpResultStatusEnum.NotAcceptable) type = 'technical';
        else if (error.status === HttpResultStatusEnum.NotFound) type = 'notFound';
        else if (error.status === HttpResultStatusEnum.BadRequest) type = 'badRequest';

        else if (error.status === HttpResultStatusEnum.TooManyRequests)
            return Promise.reject({ type : 'tryLater', code : error.status,  message : error.message, data : error });

        else if (error.status === HttpResultStatusEnum.Canceled)
            return Promise.reject({ type : 'technical', code : error.status,  message : error.message, data : error});

        return Promise.reject({ type : type, message : error.message, code: error.code ? error.code : error.status, data : error.data });
    }
}
