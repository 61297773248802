import { ResourceReferences } from '~/i18n/index';

const resources : ResourceReferences = {
    common : {
        actions : {
            cancel : `Annuler`
        },

        alert : {
            title :{
                errorMessageTitle : `Oouups....`
            },
            message : {
                defaultUnAuthorizedErrorMessage : `Tu n'es pas autorisé à faire cette action.`,
                defaultNotFoundErrorMessage : `La route n'existe pas.`,
                defaultTechnicalErrorMessage : `Désolé, il y'a une erreur :(`,
                defautltNotImplementedErrorMessage : `Non implémenté.`
            }
        },

        error :  {
            validation : {
                fieldRequiredMessage : `Le champs est requis`,
                wrongEmailFormatMessage : `Le format de l'email n'est pas correct`
            }
        }
    }
};

export default resources;
