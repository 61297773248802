import logManager from "./logManager";

let __initialized : boolean = false;

class GeoLocationManager {
    private pendingGeoLoc : boolean = false;
    private lastPosition : GeolocationPosition = null;

    public async initialize() {
    
        if(__initialized)
            return; 

        await this.getCurrentPosition();

        __initialized = true;
    }

    public async getCurrentPosition(renew : boolean = false) : Promise<GeolocationPosition> {
        
        if(!renew && this.lastPosition)
            return this.lastPosition;


        const options = {
            enableHighAccuracy: true,
            //timeout: 1000,
            maximumAge: 0
        };

        return new Promise((resolve, reject) => {
            this.pendingGeoLoc = true;
            navigator.geolocation.getCurrentPosition( 
                (pos) => {
                    this.lastPosition = pos;
                    return resolve(pos);
                }, 
                (err) => { 
                    logManager.error('GeoLocationManager.getCurrentPosition', err.message, err)
                },
                options
            );  
        });
    }
}

export default new GeoLocationManager();
