export default {
  bind: function(el, binding, vnode) {
    el.pressEvent = function(event) {

      binding.arg = binding.arg || ['enter'];
      
      const events = Array.isArray(binding.arg) ? binding.arg.map(m => m && m.toLowerCase()) : [binding.arg.toLowerCase()];

      if (events.includes(event.key && event.key.toLowerCase())) {        
        emit(vnode, 'press' , {});
        event.preventDefault();
      }
    };
    el.addEventListener('keydown', el.pressEvent);
  }
};

var emit = (vnode, name, data) => {
  var handlers = (vnode.data && vnode.data.on) ||
    (vnode.componentOptions && vnode.componentOptions.listeners);

  if (handlers && handlers[name]) {
    handlers[name].fns(data);
  }
}