import { InputGroupPlugin } from "bootstrap-vue";
import { GeoGouvFeatureApiModel } from "~/@api/geoGouvApi/models/geoGouvApiModels";
import { GeoLocationApiModel } from "~/@api/runLinkedApi/models/geoLocationApiModels";
import { Guid } from '~/@core/guid';
import { ItemMinViewModel } from "./commonViewModels";

export type GeoAddressViewModel = ItemMinViewModel & {    
    geoId : string
    label : string,
    houseNumber : string,
    name : string,
    postCode: string,
    cityCode : string,
    city : string,
    street : string,
    longitude : number,
    latitude : number,
    context : string
}

export type GeoLocationViewModel = {
    geoLocationId : Guid,
    name : string,
    city : string,
    label : string,
    longitude: number,
    latitude : number,
    zoom : number,
    resolution : number
}

class GeoLocationModels{
    public map_GeoLocationApiModel_to_GeoLocationViewModel(x : GeoLocationApiModel) : GeoLocationViewModel {
        if(!x) return null;
            
        return {
            city : x.city,
            geoLocationId : x.geoLocationId,
            label : x.label,
            latitude : x.latitude,
            longitude : x.longitude,
            name : x.name,
            resolution : x.resolution,
            zoom : x.zoom
        };
    }

    public map_GeoLocationViewModel_to_GeoLocationApiModel(x : GeoLocationViewModel) : GeoLocationApiModel {
        if(!x) return null;
            
        return {
            city : x.city,
            geoLocationId : x.geoLocationId,
            label : x.label,
            latitude : x.latitude,
            longitude : x.longitude,
            name : x.name,
            resolution : x.resolution,
            zoom : x.zoom
        };
    }
    
    public map_GeoGouvFeatureApiModel_to_GeoAddressViewModel(x : GeoGouvFeatureApiModel) : GeoAddressViewModel {
        if(!x) return null;

        return {
            id : Guid.create(),
            geoId : x.properties.id,
            city: x.properties.city,
            cityCode : x.properties.citycode,
            houseNumber : x.properties.housenumber,
            label : x.properties.label,
            longitude : x.geometry.coordinates[0],
            latitude : x.geometry.coordinates[1],
            name : x.properties.name,
            postCode : x.properties.postcode,
            street : x.properties.street,
            context: `${x.properties.city}, ${x.properties.context}`
        };
    }
}

export default new GeoLocationModels();