
export type CacheKeysCoreReference = {
};

export type ConfigKeysCoreReference = {
};

export type RouteKeysCoreReference = {
};

export type FeatureKeysCoreReference = {
};

export type ScreenSizeInfo = {
    edge : number
    type : ScreenSizeType
}

export type ScreenSizeType = 'xs' | 'sm'| 'md' | 'lg' | 'xl' | 'xxl';

export class ScreenSizes {
    
    public static current : ScreenSizeInfo = null;
    public static readonly XS : ScreenSizeInfo = { edge : 0, type : "xs"};
    public static readonly SM : ScreenSizeInfo = { edge : 576, type : "sm"};
    public static readonly MD : ScreenSizeInfo = { edge : 768, type : "md"};
    public static readonly LG : ScreenSizeInfo = { edge : 992, type : "lg"};
    public static readonly XL : ScreenSizeInfo = { edge : 1200, type : "xl"};
    public static readonly XXL : ScreenSizeInfo = { edge : 1400, type : "xxl"};

    public static getAll() : ScreenSizeInfo[] {
        return [
            this.XS,
            this.SM,
            this.MD,
            this.LG, 
            this.XL,
            this.XXL,
        ]
    }
}


class CoreAppReference {
    public ROOT_EMIT_SCREENSIZECHANGED : string = 'app::screenSizeChanged';
    public ROOT_EMIT_LOADING : string = 'app::loading';
    public ROOT_EMIT_REFRESH : string = 'app::refresh';

    public ROOT_EMIT_NATIVE_DEVICEREADY : string = 'app::deviceReady';
    public ROOT_EMIT_NATIVE_DEVICEPAUSE : string = 'app::devicePause';
    public ROOT_EMIT_NATIVE_DEVICERESUME : string = 'app::deviceResume';
    public ROOT_EMIT_NATIVE_DEVICEERROR : string = 'app::deviceError';

}
export default new CoreAppReference();