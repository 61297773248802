import { MemberApiModel, MemberListItemApiModel, DetailedMemberApiModel, MemberLinkStatusEnum, MemberVisibilityEnum, MeasureSystemEnum, SpeedUnitEnum } from "~/@api/runLinkedApi/models/memberApiModels";
import { Guid } from '~/@core/guid';
import geoLocationModelsMapper, { GeoLocationViewModel } from "~/@viewModels/geoLocationViewModels";
import { ItemMinViewModel } from "./commonViewModels";

export type inviteMemberViewModel = {
    memberId : Guid;
    email : string;
    message : string;
}

export type DetailedMemberViewModel = MemberViewModel &{
    pictureDataUrl? : string;
    speed? : number;
    distance? : number;
    geoLoc? : GeoLocationViewModel;
    description : string;

    visibility : MemberVisibilityViewType;
    measureSystem : MeasureSystemViewType;
    speedUnit : SpeedUnitViewType;

    outsideInvite? : boolean;
}

export type MemberViewModel = {
    memberId : Guid;
    email : string;
    firstName : string;
    lastName : string;
    userName : string; 
    pictureUrl : string;
}

export type MemberListItemViewModel = MemberViewModel & ItemMinViewModel & {
    invitationToken? : string;
    status : MemberLinkStatusViewType;
}

export type MemberLinkStatusViewType = 'linked' | 'invitingPending' | 'invitedPending' | 'refused';
export type MeasureSystemViewType = 'metric' | 'imperial';
export type SpeedUnitViewType = 'distancePerDuration' | 'durationPerDistance';
export type MemberVisibilityViewType = 'public' | 'private' | 'links';

class MemberViewModels {

    public map_MemberApiModel_to_MemberViewModel(x : MemberApiModel) : MemberViewModel {
        if(!x) return null;

        return {
            memberId : x.memberId,
            email : x.email,
            firstName : x.firstName,
            lastName : x.lastName,
            userName: x.userName, 
            pictureUrl :x.pictureUrl,
        };
    }
    
    public map_MemberListItemApiModel_to_MemberListItemViewModel(x : MemberListItemApiModel) : MemberListItemViewModel {
        if(!x) return null;

        return {
            ...this.map_MemberApiModel_to_MemberViewModel(x),
            invitationToken : x.invitationToken,
            status : this.map_MemberLinkStatusEnum_to_MemberLinkStatusViewType(x.status),
            id : x.memberId
        };
    }

    public map_DetailedMemberApiModel_to_DetailedMemberViewModel(x : DetailedMemberApiModel) : DetailedMemberViewModel {
        if(!x) return null;

        return {
            ...this.map_MemberApiModel_to_MemberViewModel(x),
            pictureDataUrl: x.pictureDataUrl, 
            distance : x.distance,
            speed : x.speed,
            geoLoc : geoLocationModelsMapper.map_GeoLocationApiModel_to_GeoLocationViewModel(x.geoLoc),
            outsideInvite : x.outsideInvite || false,
            description : x.description,
            measureSystem : this.map_MeasureSystemEnum_to_MeasureSystemViewType(x.measureSystem),
            speedUnit : this.map_SpeedUnitEnum_to_SpeedUnitViewType(x.speedUnit) || 'distancePerDuration',
            visibility : this.map_MemberVisibilityEnum_to_MemberVisibilityViewType(x.visibility),
        };
    }

    public map_DetailedMemberViewModel_to_DetailedMemberApiModel(x : DetailedMemberViewModel) : DetailedMemberApiModel {
        if(!x) return null;

        return {
            memberId : x.memberId,
            email : x.email,
            firstName : x.firstName,
            lastName : x.lastName,
            userName: x.userName,
            distance : x.distance,
            speed : x.speed,
            geoLoc : geoLocationModelsMapper.map_GeoLocationApiModel_to_GeoLocationViewModel(x.geoLoc),
            description : x.description,
            measureSystem : this.map_MeasureSystemViewType_to_MeasureSystemEnum(x.measureSystem),
            speedUnit : this.map_SpeedUnitViewType_to_SpeedUnitEnum(x.speedUnit),
            visibility : this.map_MemberVisibilityViewType_to_MemberVisibilityEnum(x.visibility),
        }
    }
    
    public map_MemberLinkStatusEnum_to_MemberLinkStatusViewType(x : MemberLinkStatusEnum) : MemberLinkStatusViewType {
        if(!x) return null;

        if(x === MemberLinkStatusEnum.Linked) return 'linked';
        if(x === MemberLinkStatusEnum.InvitedPending) return 'invitedPending';
        if(x === MemberLinkStatusEnum.Refused) return 'refused';
        if(x === MemberLinkStatusEnum.InvitingPending) return 'invitingPending';

        return null;
    }
    
    public map_MemberVisibilityEnum_to_MemberVisibilityViewType(x : MemberVisibilityEnum) : MemberVisibilityViewType {
        if(!x) return null;

        if(x === MemberVisibilityEnum.Links) return 'links';
        if(x === MemberVisibilityEnum.Private) return 'private';
        if(x === MemberVisibilityEnum.Public) return 'public';

        return null;
    }
    public map_MemberVisibilityViewType_to_MemberVisibilityEnum(x : MemberVisibilityViewType) : MemberVisibilityEnum {
        if(!x) return null;

        if(x === 'private') return MemberVisibilityEnum.Private;
        if(x === 'links') return MemberVisibilityEnum.Links;
        if(x === 'public') return MemberVisibilityEnum.Public;

        return MemberVisibilityEnum.Undefined;
    }
    
    public map_MeasureSystemEnum_to_MeasureSystemViewType(x : MeasureSystemEnum) : MeasureSystemViewType {
        if(!x) return null;

        if(x === MeasureSystemEnum.Metric) return 'metric';
        if(x === MeasureSystemEnum.Imperial) return 'imperial';

        return null;
    }
    public map_MeasureSystemViewType_to_MeasureSystemEnum(x : MeasureSystemViewType) : MeasureSystemEnum {
        if(!x) return null;

        if(x === 'metric') return MeasureSystemEnum.Metric;
        if(x === 'imperial') return MeasureSystemEnum.Imperial;

        return MeasureSystemEnum.Undefined;
    }
    
    public map_SpeedUnitEnum_to_SpeedUnitViewType(x : SpeedUnitEnum) : SpeedUnitViewType {
        if(!x) return null;

        if(x === SpeedUnitEnum.DistancePerDuration) return 'distancePerDuration';
        if(x === SpeedUnitEnum.DurationPerDistance) return 'durationPerDistance';

        return null;
    }
    public map_SpeedUnitViewType_to_SpeedUnitEnum(x : SpeedUnitViewType) : SpeedUnitEnum {
        if(!x) return null;

        if(x === 'distancePerDuration') return SpeedUnitEnum.DistancePerDuration;
        if(x === 'durationPerDistance') return SpeedUnitEnum.DurationPerDistance;

        return SpeedUnitEnum.Undefined;
    }

    
    public map_MemberViewModel_to_MemberListItemViewModel(x : MemberViewModel) : MemberListItemViewModel {
        if(!x) return null;

        return {
            id: x.memberId,
            email : x.email,
            firstName : x.firstName,
            lastName : x.lastName,
            memberId : x.memberId,
            pictureUrl : x.pictureUrl,
            status : 'linked', // TODO :(JES) -> to review, but at this point the status should be 'linked'
            userName : x.userName
        };
    }
}

export default new MemberViewModels();