//#region -> IMPORT ///////////////////////////////////////////////////////////////////
import { VueResources } from './types/vueTypes';
import { Component, Vue } from "vue-property-decorator";
//#endregion

@Component
export class ComponentVueBase extends Vue {

    protected _uid!: string;

    //#region  -> COMPUTED ////////////////////////////////////////////////    
    protected get resources() : VueResources  {
        return {};
    }
    //#endregion

    //#region -> HOOKS ////////////////////////////////////////////////    
    private async created(){
        this.onCreated();
    }
    private async beforeMount() { 
        this.onBeforeMount();
    }
    private async mounted(){
        this.onMounted();
    }    
    private async beforeDestroy(){    
        this.onBeforeDestroy();
    }
    //#endregion
    
    //#region -> VIRTUALS ////////////////////////////////////////////////
    protected async onCreated() : Promise<void> {}
    protected async onBeforeMount() : Promise<void>{}
    protected async onMounted() : Promise<void> {}
    protected async onBeforeDestroy() : Promise<void> {}
    //#endregion    
}
