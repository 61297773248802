import Vue from 'vue'
import VueRouter from 'vue-router'
import Routes from './routes';

import authService from '~/@services/authService';
import navigationManager from '~/@managers/navigationManager';
import configManager from '~/@managers/configManager';
import { RouteKeyReference } from '~/@references/appReference';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  base: process.env.VUE_APP_BASE_PATH,
  mode: configManager.isAppNative ? 'hash' : 'history',
  routes: Routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title;
    const routeName : RouteKeyReference = to.name as any;
    const authenticationRequired = to.matched.some(record => record.meta.requiresAuth);
    const isUserAuthenticated = await authService.isUserAuthenticated();
    
    if(authenticationRequired && isUserAuthenticated)
    {
      if(routeName === 'auth.login' || routeName === 'landing') {
        navigationManager.pushRoute('activities');
        return
      }
  
      next();
      return;
    }
  
    if(!authenticationRequired) {
  
      if(routeName === 'landing' && isUserAuthenticated) {
        navigationManager.pushRoute('activities');
        return;
      }
  
  
      next();
      return;
    }
  
    navigationManager.pushRoute('auth.login', {
      query: { returnUrl: `${window.location.origin}${to.fullPath}`}
    });
  })


export default router;