import { Selectable } from '~/@core/collection';
import { Guid } from '~/@core/guid';
import { SearchRequest, SearchResult } from '~/@core/types/searchTypes';

export type StepViewModel = {
    name : string,
    label? : string,
    previousStep? : string,
    nextStep? : string,
}

export type StepStateViewModel = {
    step : StepViewModel,
    position : number,
    valid? : boolean,
    total? : number,

    first? : boolean,
    last? : boolean
}

export type FilterItemViewModel = {
    id : number, 
    name : string
}

export type ItemMinViewModel = {
    id : Guid
}

export type SearchModelBase<TModel extends ItemMinViewModel> = {
    history? : SearchRequest[],
    search : string,    
    items : Selectable<TModel>[]
}

export type SampleListModel<TModel> = {
    totalCount : number,
    items : TModel[]
}

export type DescriptionItemModel = ItemMinViewModel & {    
    name : string
}

class CommonModel {
    
    public map_SearchResultApiModel_to_SearchResultViewModel<TItem, TItemModel>(result : SearchResult<TItem>, mapper? : (item : TItem) => TItemModel) : SearchResult<TItemModel> {
        return {
            total : result.total,
            pages : result.pages,
            items : result.items.map(x => mapper(x)),
            filters : result.filters
        }
    }    

}

export default new CommonModel();