import { Queryable } from './collection';

export class Dictionary<TKey extends string | number, TValue> extends Queryable<TValue, TKey>{

  constructor(items : Map<TKey, TValue> | Array<TValue> = null) {
    super(items);
    this.Items.forEach((v, k) => this[k.toString()] = v);
  }

  [key: string] : any;

  public item(key: TKey)
  {
    if (!key) return null;
    return this[key.toString()] as TValue;
  }

  public add(key : TKey, value : TValue, overrideIfExists : boolean = false) 
  {
    const containsKey = this.containsKey(key);
    if(!overrideIfExists && containsKey)
      throw Error(`Key must be unique. ["${key}"] already exists`);

    this.Items.set(key, value)
    this[key.toString()] = value;
  }
  
  public update(key : TKey, value : TValue) 
  {
    this.Items.set(key, value)
    this[key.toString()] = value;
  }

  public remove(key : TKey) : boolean
  {
    const containsKey = this.containsKey(key);
    
    if(containsKey) {
      this.Items.delete(key);
      delete this[key.toString()];
      return true;
    }

    return false;
  }

  public containsKey(key : TKey) : boolean
  {
    return this.Items.has(key);
  }

  public removeAll()
  {
    this.Items.forEach((value, key) => {
      delete this[key.toString()];
    })
    this.Items.clear();
  }
}