class RunlinkedApiReference {
    public readonly Routes = {
        account : {
            password : {
                forgotten : {
                    post : `/api/identity/account/password/forgotten`
                },
                reset : {
                    post : `/api/identity/account/password/reset?returnUrl=/`
                }
            }
        },
        activity :{
            list : {
                post : '/api/app/activity/list'
            },
            get : (id) => `/api/app/activity/get?activityId=${id}`,
            post : `/api/app/activity`,
            put : '/api/app/activity',
            delete : (id) => `/api/app/activity?activityId=${id}`,
            message : {
                post : `/api/app/activity/message`
            },
            attendee : {
                updateStatus : {
                    put : '/api/app/activity/attendee/status'
                },
                               
                AddRequest : {
                    post : '/api/app/activity/attendee/request'
                }
            }
        },
        group :{
            list : {
                post : '/api/app/group/list'
            },
            get : (id) => `/api/app/group/get?groupId=${id}`,
            post : `/api/app/group`,
            put : '/api/app/group',
            message : {
                post : `/api/app/group/message`
            },
            activity : {
                post : `/api/app/group/activity`,
            }
        },
        identity : {
            oauth : {
                token : {
                    post : '/identity/oauth/token'
                },
                externallogin : {
                    get : (returnUrl : string) => `/api/identity/oauth/externallogin?returnUrl=${returnUrl}`,
                    token : {
                        post : '/api/identity/oauth/externallogin/token'
                    },
                    register : {
                        post : '/api/identity/account/externallogin/register'
                    }
                }
            }
        },
        invitation : {
            post : `/api/app/invitation`,
            accept : {
                post : `/api/app/invitation/accept`
            },
            list  : {
                pending : {
                    get :  `/api/app/invitation/pending/list`
                }
            }
        },
        member : {
            invite : {
                post : `/api/app/member/invite`,
            },
            list : {
                post : `/api/app/member/list`
            },
            get :  (id) => `/api/app/member?memberId=${id}`
        },
        notification : {
            list : {
                post : `/api/app/notification/list`
            },
            last : {
                get : `/api/app/notification/last`
            },
            read : {
                put : `/api/app/notification/read`
            },
            setDevice : {
                post : `/api/app/notification/device`
            }
        },
        
        profile : {
            get : `/api/app/profile`,
            member : {
                put : `/api/app/profile/member`,
                
                picture : {
                    upload : {
                        post : `/api/app/profile/member/picture/upload`
                    }
                }
            },
            notification : {
                put : `/api/app/profile/notification`
            }
        }
    };    
}

export default new RunlinkedApiReference();