import { KeyOf } from '~/@core/types/commonTypes';
import i18n, { ResourceReferences } from '~/i18n';
import moment, { Moment } from 'moment';

class RegionManager {
    public translate(key: KeyOf<ResourceReferences>, args? : any): string {   
        return i18n.t(key, args).toString();
    }
    
    public changeLocale(lang : 'fr') {
        i18n.locale = lang;
    }
    
    public getCurrentLocale(){
        return i18n.locale;
    }

    public getMoment(date : string | Date = new Date()) : Moment {            
        return moment(date).locale(i18n.locale);
    }
}

export default new RegionManager();