import Vue from 'vue';
import App from './app.vue';
import router from './router';
import './bootstrap';

import logManager from './@managers/logManager';
import pushManager from './@managers/pushManager';
import memberService from './@services/memberService';
import { AppError } from './@core/types/errorTypes';
import sessionManager from './@managers/sessionManager';
import geoLocationManager from './@managers/geoLocationManager';

import OneSignalVue from 'onesignal-vue';
Vue.use(OneSignalVue);

//#region -> DIRECTIVES //////////////////////////////////////////////////////////////////
import globalDirectives from "./globalDirectives";
Vue.use(globalDirectives);
//#endregion

//#region -> COMPONENTS //////////////////////////////////////////////////////////////////
import globalComponents from "./globalComponent";
Vue.use(globalComponents);
//#endregion

import "./assets/scss/runlinked.scss";
import coreAppReference from './@references/coreAppReference';
import appReference from './@references/appReference';
import configManager from './@managers/configManager';
import webPushManager from './@managers/webPushManager';

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
    logManager.error('Global.Error', `${vm ? vm.constructor.name : ''} error`, err, vm, info);
};

window.onerror = function (message, source, lineno, colno, error) {
};

window.addEventListener("unhandledrejection", function (event){
    // TODO :(JES) -> log unhandledRejection quietly
    event.preventDefault();
    event.stopPropagation();
    return false;
});

logManager.log(null, () => [appReference.getVersionAscii(configManager.getVersion())], 'primary');

//#region -> VUE INSTANCE //////////////////////////////////////////////////////////////////
let vueInstance : Vue = null;
const initializeVue = async () => {
    await memberService.getProfile(false).then(
        response => response,
        async (error : AppError ) => {
            await sessionManager.clearActiveSession();
        }
    );

    vueInstance = new Vue({
        router,
        render: h => h(App)
    });

    vueInstance.$mount('#app');


    return vueInstance;
  }

if (configManager.isAppNative) {

    document.addEventListener('deviceready',  async () => {
        pushManager.initialize();
        geoLocationManager.initialize();

        await initializeVue();

        vueInstance.$root.$emit(coreAppReference.ROOT_EMIT_NATIVE_DEVICEREADY);
    }, false);

    document.addEventListener('pause',  () => {
        geoLocationManager.getCurrentPosition(true);
        vueInstance.$root.$emit(coreAppReference.ROOT_EMIT_NATIVE_DEVICEPAUSE);
    }, false);

    document.addEventListener('resume',  () => {
        geoLocationManager.getCurrentPosition(true);
        vueInstance.$root.$emit(coreAppReference.ROOT_EMIT_NATIVE_DEVICERESUME);
    }, false);

    window.addEventListener('cordovacallbackerror',  (event : any) => {
        vueInstance.$root.$emit(coreAppReference.ROOT_EMIT_NATIVE_DEVICERESUME, event.error);
    }, false);

  }
  else {

    // analyticsManager.initialize();
    webPushManager.initialize()
    geoLocationManager.initialize();

    (async () => {
        await initializeVue();
        vueInstance.$root.$emit('app:deviceReady', 'Device is ready !');
    })();
  }
  //#endregion
