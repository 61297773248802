
import { NativeStorage } from "@awesome-cordova-plugins/native-storage";
import { StorageKeyReference } from "~/@references/appReference";
import configManager from "./configManager";
import logManager from "./logManager";

class StorageManager {

  public async set(key: StorageKeyReference, data: any): Promise<void> {
      const dataEncoded = btoa(encodeURIComponent(typeof(data) === 'object' ? JSON.stringify(data) : data));

      logManager.debug('Storage.set', key, data, dataEncoded);

      if (configManager.isAppNative) {
        await NativeStorage.setItem(key, dataEncoded)
      } 
      else {
        localStorage.setItem(key, dataEncoded);
      }
  }

  public async get<T>(key: StorageKeyReference): Promise<T> {
    if (configManager.isAppNative) {
        const nativeData = await NativeStorage.getItem(key)
            .then((res) => { return res })
            .catch((err) => {
                if(err.code === 2) {
                    return null
                }
                logManager.debug('StorageManager.get', err);
            })
      
        if (!nativeData)
            return null;
            
        
        const nativeDataDecoded = decodeURIComponent(atob(nativeData));
        
        const dataToReturn  = nativeDataDecoded.startsWith("{") ? JSON.parse(nativeDataDecoded) as T : nativeDataDecoded as any as T;
        return dataToReturn;
    } 
    else {
        const data = localStorage.getItem(key);
        
        if (!data) 
            return null;
        
        const dataDecoded = decodeURIComponent(atob(data));
        const dataToReturn = dataDecoded.startsWith("{") ? JSON.parse(dataDecoded) as T : dataDecoded as any as T;
        return dataToReturn;
    }
  }
  
  public async remove(key: StorageKeyReference): Promise<void> {    
  
    if (configManager.isAppNative) {
        await NativeStorage.remove(key);
    } 
    else {
        localStorage.removeItem(key);
    }
  }
}

export default new StorageManager();