
//#region -> IMPORTS ////////////////////////////////////////////////////
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Loader from '~/components/loader.vue';
import $ from 'jquery';
import coreAppReference, { ScreenSizeInfo, ScreenSizes } from "~/@references/coreAppReference";
import regionManager from "./@managers/regionManager";
//https://github.com/BoxFactura/pulltorefresh.js
import PullToRefresh from "pulltorefreshjs";
import configManager from "./@managers/configManager";
//#endregion

@Component({
    components : {
        Loader
    }
})
export default class APP extends Vue {

    $refs! : {
    }

    private loading : boolean = true;


    //#region -> COMPUTED ////////////////////////////////////////////////////
    private get nativeAppClass() {
        if(configManager.isAppNative)
            return `app-${configManager.getNativePlatform()}`;

        return '';
    }
    //#endregion

    //#region -> HOOKS ////////////////////////////////////////////////////
    private async created() {

        this.$root.$on(coreAppReference.ROOT_EMIT_LOADING, (loading) => this.loading = loading);

        this.$root.$on('app:deviceReady', (loading) => console.log(loading));

        window.addEventListener('resize', this.sizePage);

        regionManager.changeLocale('fr');

    }

    private async mounted(){
        this.sizePage();

        PullToRefresh.init({
            mainElement: 'body',
            distIgnore: 0,
            onRefresh: () => { this.$root.$emit(coreAppReference.ROOT_EMIT_REFRESH, ''); }
        });
    }

    private async beforeDestroy() {
        this.$root.$off(coreAppReference.ROOT_EMIT_LOADING);
    }
    //#endregion

    //#region -> PUBLICS ////////////////////////////////////////////////////

    //#endregion

    //#region -> PRIVATES ////////////////////////////////////////////////////
    private sizePage() : ScreenSizeInfo {

        const $window = $(window);
        const $document = $(document);

        const context = {
            $window: $window,
            $body: $document.find(`body`),
            $html: $document.find(`html`),
            width: $window[0].innerWidth,
            height: $window[0].innerHeight
        };

        const changeSize = (newSize : ScreenSizeInfo) => {
            if (newSize === null || ScreenSizes.current === null || newSize.type !== ScreenSizes.current.type) {
                ScreenSizes.current = newSize;
                this.$root.$emit(coreAppReference.ROOT_EMIT_SCREENSIZECHANGED, ScreenSizes.current);
            }

            context.$body.css('--vh', `${$window.innerHeight() * 0.01}px`);
            return ScreenSizes.current;
        }

        // -> Resize decision
        if (context.width >= ScreenSizes.XXL.edge) return changeSize(ScreenSizes.XXL);
        if (context.width >= ScreenSizes.XL.edge && context.width < Number(ScreenSizes.XXL.edge)) return changeSize(ScreenSizes.XL);
        if (context.width >= ScreenSizes.LG.edge && context.width < Number(ScreenSizes.XL.edge)) return changeSize(ScreenSizes.LG);
        if (context.width >= ScreenSizes.MD.edge && context.width < Number(ScreenSizes.LG.edge)) return changeSize(ScreenSizes.MD);
        if (context.width >= ScreenSizes.SM.edge && context.width < Number(ScreenSizes.MD.edge)) return changeSize(ScreenSizes.SM);

        return changeSize(ScreenSizes.XS);
    }
    //#endregion
};
