
import { OneSignalPlugin } from "onesignal-cordova-plugin";
import { OpenedEvent } from "onesignal-cordova-plugin/types/Notification";
import { DeviceState } from "onesignal-cordova-plugin/types/Subscription";
import cacheManager from "./cacheManager";
import configManager from "./configManager";
import logManager from "./logManager";
import navigationManager from "./navigationManager";
import storageManager from "./storageManager";

let  __initialized : boolean = false;

interface PushAddionalData {
    redirectUrl?: string;
    [key: string]: any;
}

class PushManager {
    // Cordova plugins aren't typed yet, so need to use any
    public get oneSignal(): OneSignalPlugin { return (window as any).plugins.OneSignal; }
    public didInit: boolean = false;
    private _correlationId : string = null;

    private _notificationHandler : (notification) => Promise<void>;

    public async initialize() {

        
        if(__initialized)
            return;
            

        this._correlationId = await storageManager.get<string>('correlationId');

        await this.oneSignalInit();

        __initialized = true;
    }

    public async getPushCorrelationId(fromCache : boolean = true): Promise<string> {
        if (fromCache && this._correlationId) 
            return this._correlationId;

        return new Promise((resolve, reject) => {
            this.oneSignal.getDeviceState(async (status: DeviceState) => {
                
                if(!status.userId)
                    return reject(null);

                this._correlationId = status.userId;
                await storageManager.set('correlationId', this._correlationId);

                return resolve(status.userId);
            });
        });
    }

    public async clear(){
        this._correlationId = null;
        await storageManager.remove('correlationId');
    }

    public onNotification(handler : (notification) => Promise<void>) {
        this._notificationHandler = handler;
    }

    private async oneSignalInit() {

        this.oneSignal.setLogLevel(6, 0);
        this.oneSignal.setAppId(configManager.getConfig('oneSignalAppId'));
            
        logManager.debug('PushManager.setNotificationOpenedHandler');
        
        this.oneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent) => {
            const notification = notificationReceivedEvent.getNotification();
            logManager.debug('PushManager.setNotificationWillShowInForegroundHandler', 'handling push coming', notification, this._notificationHandler);    
            cacheManager.clearCachedPromise("notificationList");
            
            if(this._notificationHandler)
                this._notificationHandler(notification);

            notificationReceivedEvent.complete(null);
          }
        )

        this.oneSignal.setNotificationOpenedHandler( 
            (openedEvent: OpenedEvent) => {
                
                const notification = openedEvent.notification;
                const additionalData = notification.additionalData as PushAddionalData;
            
                logManager.debug('PushManager.setNotificationOpenedHandler', 'handling push press event', notification, additionalData);

                if (additionalData.redirectUri) {
                    // if(additionalData.member)
                    //     alertManager.showNotification(notification.body, memberViewModels.map_MemberApiModel_to_MemberViewModel(additionalData.member))

                    navigationManager.pushPath(additionalData.redirectUri);

                    // navigationManager.pushRoute('notifications');
                }
            }
        );

        this.oneSignal.promptForPushNotificationsWithUserResponse(
            (accepted: boolean) => {
                console.log('User accepted notifications: ' + accepted);
            }
        );

        logManager.debug('PushManager.getPushCorrelationId');
        await this.getPushCorrelationId();
    }
}

export default new PushManager();
